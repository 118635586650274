import { useEffect, useState } from "react";
import { Heading } from "../../../../atoms/Heading";
import { getData } from "../../../../../services";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import TabStrip from "../../../../atoms/tabstrip";
import { Box } from "@mui/system";
import BasicInfo from "./tabs/BasicInfo";
import SummaryInterest from "./tabs/SummaryInterest";
import InterestDetails from "./tabs/InterestDetails";
import AdditionalDocUpload from "./tabs/AdditionalDocUpload";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import Schedule from "./tabs/Schedule";
import { getPandaApi } from "../../../../../services/pandaDocs";
import EditFunding from "./tabs/EditFunding";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";

const FundedView = () => {
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const location = useLocation();
  const [fundedDataById, setFundedDataById] = useState({});
  const [selectedImage, setSelectedImage] = useState([]);
  const [pricingData, setPricingData] = useState({});
  const [rateData, setRateData] = useState({});
  const [pandaDocStatus, setPandaDocStatus] = useState({});

  const [activeTab, setActiveTab] = useState("Basic Info");
  const onTabChange = (event, value) => {
    setActiveTab(value);
  };
  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const handleMultipleImages = (file) => {
    setSelectedImage((prev) => [...prev, file]);
  };

  const handleRemoveImage = (imageToRemove) => {
    setSelectedImage((prevImages) =>
      prevImages.filter((image) => image !== imageToRemove),
    );
  };

  const handlePandaDocsStatus = async (type) => {
    const response = await getPandaApi({
      endpoint: `https://api.pandadoc.com/public/v1/documents/${fundedDataById?.documentsInvoice?.docInvoiceId}`,
      params: {},
      token: "0752d8cf5fef4dfefa1c9ed82eef75295fda72ec",
    });
    if (response && type !== "initial") {
      toast.success("Schedule Status Refreshed",{style: confimationStyles, duration: 1000});
    }
    setPandaDocStatus(response);
  };

  const data = [
    {
      id: 1,
      label: "Basic Info",
      view: (
        <BasicInfo
          fundedDataById={fundedDataById}
          pandaDocStatus={pandaDocStatus}
          handlePandaDocsStatus={handlePandaDocsStatus}
        />
      ),
    },
    {
      id: 2,
      label: "Interest Summary",
      view: (
        <SummaryInterest
          pricingData={pricingData}
          fundedDataById={fundedDataById}
        />
      ),
    },
    {
      id: 3,
      label: "Interest Details",
      view: (
        <InterestDetails
          pricingData={pricingData}
          fundedDataById={fundedDataById}
        />
      ),
    },
    {
      id: 4,
      label: "Upload Additional Support",
      view: (
        <AdditionalDocUpload
          fundedDataById={fundedDataById}
          pricingData={pricingData}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          handleMultipleImages={handleMultipleImages}
          handleRemoveImage={handleRemoveImage}
        />
      ),
    },
    {
      id: 5,
      label: "Schedule",
      view: (
        <Schedule
          fundedDataById={fundedDataById}
          activeTab={activeTab}
          pandaDocStatus={pandaDocStatus}
          handlePandaDocsStatus={handlePandaDocsStatus}
        />
      ),
    },
    {
      id: 7,
      label: "Edit",
      view: <EditFunding fundedDataById={fundedDataById} />,
    },
  ];

  const getFundedDataById = async () => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setFundedDataById(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (activeTab) {
      getFundedDataById();
    }
  }, [activeTab]);

  useEffect(() => {
    if (fundedDataById) {
      getCounterPartyDetails();
    }
  }, [fundedDataById?.client_id, activeTab]);

  const getCounterPartyDetails = async () => {
    try {
      if (
        fundedDataById?.transaction_type === "Client" ||
        fundedDataById?.transaction_type === "Revolver" ||
        fundedDataById?.transaction_type === "Note"
      ) {
        const res = await getData({
          endpoint: "couterParty/getCounterPartyById",
          token: cookies.t,
          params: {
            id: fundedDataById?.client_id,
          },
        });
        if (res?.data) {
          const priceRes = await getData({
            endpoint: "price/getPriceById",
            token: cookies.t,
            params: { id: res?.data?.pricing_id },
          });
          setPricingData(priceRes?.data);
          if (priceRes?.data) {
            const rateRes = await getData({
              endpoint: "rates/getRateById",
              token: cookies.t,
              params: { id: priceRes?.data?.base_rate },
            });
            setRateData(rateRes?.data);
          }
        }
      } else if (
        fundedDataById?.transaction_type === "Supplier" ||
        fundedDataById?.transaction_type === "Supplier Credit" ||
        fundedDataById?.transaction_type === "Supplier Credit-1"
      ) {
        // const res = await getData({
        //   endpoint: "couterParty/getCounterPartyById",
        //   token: cookies.t,
        //   params: {
        //     id: fundedDataById?.supplier_id,
        //   },
        // });
        const res = await getData({
          endpoint: "couterParty/getCounterPartyById",
          token: cookies.t,
          params: {
            id: fundedDataById.client_id,
          },
        });
        if (res?.data) {
          const priceRes = await getData({
            endpoint: "price/getPriceById",
            token: cookies.t,
            params: { id: res?.data?.pricing_id },
          });
          setPricingData(priceRes?.data);
          if (priceRes?.data) {
            const rateRes = await getData({
              endpoint: "rates/getRateById",
              token: cookies.t,
              params: { id: priceRes?.data?.base_rate },
            });
            setRateData(rateRes?.data);
          }
        }
      } else if (fundedDataById?.transaction_type === "Obligor") {
        const res = await getData({
          endpoint: "couterParty/getCounterPartyById",
          token: cookies.t,
          params: {
            id: fundedDataById?.obligor_id,
          },
        });
        if (res?.data) {
          const priceRes = await getData({
            endpoint: "price/getPriceById",
            token: cookies.t,
            params: { id: res?.data?.pricing_id },
          });
          setPricingData(priceRes?.data);
          if (priceRes?.data) {
            const rateRes = await getData({
              endpoint: "rates/getRateById",
              token: cookies.t,
              params: { id: priceRes?.data?.base_rate },
            });
            setRateData(rateRes?.data);
          }
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <div className="fixed-part">
        <Breadcrumb
          labels={["Funding", "Funding View"]}
          stepsToRemove={1}
          customState={location?.state?.tab}
        />
        <Heading className="HeadingStyle">View Funding</Heading>
        <TabStrip activeTab={activeTab} onTabChange={onTabChange} data={data} />
      </div>

      <Box sx={{ width: "100%" }}>
        <Box>{_getComponent(activeTab)}</Box>
      </Box>
    </>
  );
};
export default FundedView;
