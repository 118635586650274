import { memo, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getData } from "../../../../../services";
import NoContentCard from "../../../../atoms/NoContentCard";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import { useParams } from "react-router-dom";
import CounterPartyBasicInfo from "./counterpartyBasicInfo";
import style from "./index.module.scss";
import moment from "moment";
import { formatNumberInternational } from "../../../../../helpers/capatalizeLetters";

const CounterPartyDue = () => {
  const [cookies] = useCookies(["t"]);
  const { id } = useParams();
  const [duesData, setDuesData] = useState([]);
  const [clientData, setClientData] = useState({});

  const getDueData = async (fundingData) => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingByClientId",
        params: {
          id: fundingData._id,
          status: "Funded",
          trans_type: fundingData?.counter_type,
        },
        token: cookies.t,
      });
      setDuesData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      return res?.data;
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getCounterDataById();
        setClientData(result);
        if (result) {
          await getDueData(result);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Content Found"
          subtitle=""
          type="See Dues"
          classes="d-flex align-items-center"
        />
      </>
    );
  });
  const columns = [
    {
      field: "client_name",
      headerName: "Client",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div title={params?.row?.client_name}>{params?.row?.client_name}</div>
        );
      },
    },
    {
      field: "obligor_name",
      headerName: "Obligor",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div title={params?.row?.obligor_name}>
            {params?.row?.obligor_name}
          </div>
        );
      },
    },
    {
      field: "supplier_name",
      headerName: "Supplier",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div title={params?.row?.supplier_name}>
            {params?.row?.supplier_name}
          </div>
        );
      },
    },
    {
      field: "due_date",
      headerName: "Due Date",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div title={moment(params?.row?.due_date).format("YYYY-MM-DD")}>
            {moment(params?.row?.due_date).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      field: "client_ref",
      headerName: "Client Ref",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div title={params?.row?.invoice_number}>
            {params?.row?.invoice_number}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.status === "Funded" ? "Open" : ""}</div>;
      },
    },
    {
      field: "cost_value",
      headerName: "Cost Value",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{formatNumberInternational(parseFloat(params?.row?.cost_value))}</div>;
      },
    },
    {
      field: "face_value",
      headerName: "Face Value",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{formatNumberInternational(parseFloat(params?.row?.face_value))}</div>;
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.currency}</div>;
      },
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div title={params?.row?.transaction_type}>
            {params?.row?.transaction_type}
          </div>
        );
      },
    },
  ];

  console.log("duesData", duesData);
  return (
    <>
      <div className={style.duesWrap}>
        <div className={style.clientBasicInfo}>
          <CounterPartyBasicInfo clientData={clientData} />
        </div>
        <div className={style.tableInfoWrap}>
          <CustomDatagrid
            rows={duesData}
            getRowId={(row) => row?._id}
            columns={columns}
            height={() => 50}
            hoverColor="ffffff"
            CustomNoRowsOverlay={CustomNoRowsOverlay}
          />
        </div>
      </div>
      {/* {duesData?.pageDetails?.count > 10 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={fundingData?.[0]?.pageDetails?.count}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )} */}
    </>
  );
};

export default CounterPartyDue;
