import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import CounterPartyBasicInfo from "../counterpartyBasicInfo";
import style from "./index.module.scss";
import { getData, postFormData } from "../../../../../../services";
import MinusIcon from "../../../../../icons/minusIcon";
import PlusIcon from "../../../../../icons/PlusIcon";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";

const MonitoringAdd = ({ setActiveTab }) => {
  const [cookies] = useCookies(["t"]);
  const { id } = useParams();
  const [clientData, setClientData] = useState({});
  const [reportingData, setReportingData] = useState({});
  const [selectedDate, setSelectedDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const transformDataToState = (data) => {
    return data?.reduce((acc, item) => {
      const key = item?.name?.toLowerCase()?.replace(/[^a-z]/g, "");
      acc[key] = [null];
      acc[key] = {
        files: [null], // Initialize files with [null]
        displayName: item?.name, // Store the original name for display
      };
      return acc;
    }, {});
  };

  const [monitoringFiles, setMonitoringFiles] = useState({});

  const handleFileChange = (e, fileType, index) => {
    const file = e?.target?.files[0]; // The new file selected
    if (!file) return; // Exit if no file is selected

    setMonitoringFiles((prevState) => {
      const updatedFiles = [...(prevState[fileType]?.files || [])]; // Clone existing files array
      updatedFiles[index] = file; // Update the specific file in the array

      return {
        ...prevState,
        [fileType]: {
          ...prevState[fileType],
          files: updatedFiles, // Update the files array
        },
      };
    });
  };

  const addFileInput = (fileType) => {
    setMonitoringFiles((prevState) => ({
      ...prevState,
      [fileType]: {
        ...prevState[fileType], // Preserve other properties like `displayName`
        files: [...(prevState[fileType]?.files || []), null], // Add a new `null` entry to the `files` array
      },
    }));
  };

  const removeFileInput = (fileType, index) => {
    setMonitoringFiles((prevState) => {
      const newFiles = [...(prevState[fileType]?.files || [])];
      if (index >= 0) {
        newFiles.splice(index, 1); // Remove the file at the specified index
      }

      return {
        ...prevState,
        [fileType]: {
          ...prevState[fileType], // Preserve other properties like `displayName`
          files: newFiles, // Update the `files` array
        },
      };
    });
  };

  const convertToFormData = (data) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value?.files)) {
        // Append each file in the array with a unique key
        value?.files?.forEach((file, index) => {
          formData.append(`${key}`, file);
        });
      }
    });

    return formData;
  };

  const handleSubmit = async (values) => {
    // const formData = new FormData();
    // Append each file under its corresponding fieldname
    const formData = convertToFormData(monitoringFiles);

    try {
      setIsLoading(true);
      const res = await postFormData({
        endpoint: "/counterreport/createCounterReport",
        params: {
          client_id: clientData?._id,
          client_name:
            clientData?.counter_type === "Client"
              ? clientData?.counter_name
              : clientData?.client_name,
          selected_date: selectedDate,
        },
        token: cookies.t,
        data: formData,
      });
      if (res) {
        setSelectedDate(null);

        const fileInputs = document.querySelectorAll('input[type="file"]');
        fileInputs.forEach((input) => {
          input.value = ""; // Clear the file input value
        });

        setMonitoringFiles((prevState) => {
          const resetState = {};
          Object.keys(prevState).forEach((key) => {
            resetState[key] = {
              ...prevState[key],
              files: [null], // Reset to initial state with single null entry
            };
          });
          return resetState;
        });
        console.log("monitoring files", monitoringFiles);
      }
      setIsLoading(false);
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  };

  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getReportingRequirementById = async () => {
    try {
      const res = await getData({
        endpoint:
          "reporting-requirements/getPeportingRequiremetsDetailsByClientId",
        params: {
          client_id: id,
        },
        token: cookies.t,
      });
      setReportingData(res?.data?.reporting_requirement_details);
      setMonitoringFiles(
        transformDataToState(
          res?.data?.reporting_requirement_details
            ? res?.data?.reporting_requirement_details
            : {},
        ),
      );
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (id) {
      getCounterDataById();
      getReportingRequirementById();
    }
  }, [id]);

  console.log("selectedDate", typeof selectedDate);

  return (
    <>
      <div className={style.duesWrap}>
        <div className={style.clientBasicInfo}>
          <CounterPartyBasicInfo clientData={clientData} />
        </div>
        <div className={style.tableInfoWrap}>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Client Reporting Information</th>
                <th scope="col"> </th>
              </tr>
              <tbody>
                <tr>
                  <td
                    style={{
                      border: "1px solid #DEE3E7",
                      padding: "8px",
                    }}
                  >
                    Client Name:
                  </td>
                  <td
                    style={{
                      border: "1px solid #DEE3E7",
                      padding: "8px",
                    }}
                  >
                    {clientData?.counter_type === "Client"
                      ? clientData?.counter_name
                      : clientData?.client_name}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #DEE3E7",
                      padding: "8px",
                    }}
                  >
                    Report Request Email(s):
                  </td>
                  <td
                    style={{
                      border: "1px solid #DEE3E7",
                      padding: "8px",
                    }}
                  >
                    {/* {clientData?.counter_type === "Client"
                      ? clientData?.counter_name
                      : clientData?.client_name} */}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #DEE3E7",
                      padding: "8px",
                    }}
                  >
                    Last Recived As Of Report Date:
                  </td>
                  <td
                    style={{
                      border: "1px solid #DEE3E7",
                      padding: "8px",
                    }}
                  >
                    {/* {clientData?.counter_type === "Client"
                      ? clientData?.counter_name
                      : clientData?.client_name} */}
                  </td>
                </tr>
              </tbody>
            </thead>
          </table>
          <div>
            {Object?.keys(monitoringFiles).length ? (
              <>
                <form style={styles.formContainer}>
                  <div>
                    <h6 className="fw-bold">Uploading Files Dated:</h6>
                    <div
                      style={{ position: "relative", width: "100%" }}
                      className="mt-3 mb-3"
                    >
                      <DateSelector
                        placeholder="Select Date"
                        onChange={(option) => {
                          setSelectedDate(option);
                        }}
                        selectedDate={selectedDate}
                      />
                    </div>
                  </div>
                  {Object?.keys(monitoringFiles)?.map((fileType) => {
                    return (
                      <>
                        <FileInputList
                          key={fileType}
                          label={monitoringFiles?.[fileType]?.displayName} // Format label (optional)
                          fileType={fileType}
                          files={monitoringFiles?.[fileType]?.files}
                          onFileChange={handleFileChange}
                          addFileInput={addFileInput}
                          removeFileInput={removeFileInput}
                        />
                      </>
                    );
                  })}
                </form>
                <button
                  type="submit"
                  className="btn-primary btn-lg"
                  style={styles.submitButton}
                  onClick={() => handleSubmit()}
                  disabled={selectedDate?.length ? false : true}
                >
                  Save
                  {isLoading && (
                    <span
                      className="spinner-border spinner-border-sm ms-3 text-white"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </>
            ) : (
              <>
                <tr>
                  <td
                    colSpan={7} // Adjust to the total number of columns
                    style={{
                      border: "1px solid #DEE3E7",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    No Reporting Requirement Available
                  </td>
                </tr>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default MonitoringAdd;

export const FileInputList = ({
  label,
  fileType,
  files,
  onFileChange,
  addFileInput,
  removeFileInput,
}) => (
  <div style={styles.fileInputContainer}>
    <label style={styles.label}>{label}</label>
    {files.map((file, index) => (
      <div key={index} style={styles.fileRow}>
        <input
          type="file"
          accept=".pdf,.doc,.docx,.xls,.xlsx"
          onChange={(e) => onFileChange(e, fileType, index)}
          style={styles.fileInput}
        />
        {/* {file && <span style={styles.fileName}>{file.name}</span>}{" "} */}
        {/* Display file name if chosen */}
        <div style={styles.iconContainer}>
          {index > 0 && ( // Show remove button only if it's not the first input
            <button
              type="button"
              style={styles.iconButton}
              onClick={() => removeFileInput(fileType, index)}
            >
              <MinusIcon color={"#146ef5"} />
            </button>
          )}
          {index === files.length - 1 && ( // Show add button only on the last input
            <button
              type="button"
              style={styles.iconButton}
              onClick={() => addFileInput(fileType)}
            >
              <PlusIcon />
            </button>
          )}
        </div>
      </div>
    ))}
  </div>
);

const styles = {
  formContainer: {
    width: "60%",
    fontFamily: "Arial, sans-serif",
  },
  fileInputContainer: {
    marginBottom: "20px",
    padding: "10px",
    // backgroundColor: "#f0f0f0",
    border: "1px solid #f0f0f0",
    borderRadius: "4px",
  },
  fileRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between", // Ensures that the file input and buttons are spread out
    marginBottom: "10px",
  },
  fileInput: {
    flexGrow: 1, // Takes up remaining space
    marginRight: "10px",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    display: "block",
    marginBottom: "5px",
  },
  iconButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    padding: "0",
  },
  submitButton: {
    backgroundColor: "#146ef5",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  fileName: {
    marginLeft: "10px",
    fontStyle: "italic",
  },
};
