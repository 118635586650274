import React, { useCallback, useState, memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import Pagination from "../../../../atoms/Pagination";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import NoContentCard from "../../../../atoms/NoContentCard";

import { deleteData, getData, patchData } from "../../../../../services";
import { useCookies } from "react-cookie";
import CustomMenu from "../../../../molecules/CustomMenu";
import Delete from "../../../../icons/Delete";
import Modal from "../../../../atoms/modals/DeleteModal";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import View from "../../../../icons/View";
import Approve from "../../../../icons/Apporve";
import ArchiveIcon from "../../../../icons/Archive";
import Signature from "../../../../icons/Signature";
import moment from "moment/moment";
import CustomButton from "../../../../atoms/CustomButton";
import { getPandaApi } from "../../../../../services/pandaDocs";
import Refresh from "../../../../icons/Refresh";
import { formatNumberInternational } from "../../../../../helpers/capatalizeLetters";

const content = {
  Draft: {
    title: "No Funding added for Review yet!",
    subtitle: "Currently, no funding has been added for review.",
  },
  "Advance Request": {
    title: "No Funding added in Advance Request yet!",
    subtitle: "Currently, no funding has been added to Advance Request.",
  },
  Submitted: {
    title: "No Funding added in Submitted yet!",
    subtitle: "Currently, no funding are Submitted.",
  },
  Approved: {
    title: "No Funding Approved yet!",
    subtitle: "Currently, no funding have been Approved for processing.",
  },
  Allocated: {
    title: "No Funding Allocated yet!",
    subtitle: "Currently, no funding have been Allocated.",
  },
  Funded: {
    title: "No Funding Funded yet!",
    subtitle: "Currently, no funding have been Funded.",
  },
  Closed: {
    title: "No Funding Closed yet!",
    subtitle: "Currently, no funding have been Closed.",
  },
};

const Table = ({
  type,
  searchData,
  setModalStateAdvReq,
  setModalStateSubmitted,
  setModalStateApproved,
  setModalStateAllocated,
  filters,
  getAllFundingStatus,
  roleData,
  modalStateAdvReq,
  modalStateSubmitted,
  modalStateAllocated,
  modalStateApproved,
}) => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalStateArchive, setModalStateArchive] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [loader, setLoader] = useState(false);

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [pandaDocStatus, setPandaDocStatus] = useState([]);

  const [fundingData, setFundingData] = useState([]);

  const GetFundingData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "funding/getAllFundings",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          status: [type],
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
          transaction_type:
            filters?.["Transaction Type"] &&
            filters?.["Transaction Type"]?.length
              ? filters?.["Transaction Type"]
              : null,
          currency: filters?.["Currency"]?.length
            ? filters?.["Currency"]
            : null,
          start_date: filters?.startDate0,
          end_date: filters?.endDate0,
        },
      });
      getAllFundingStatus();
      setFundingData(res?.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    GetFundingData();
  }, [
    itemsPerPage,
    currentPage,
    searchData,
    type,
    modalStateAdvReq,
    modalStateSubmitted,
    modalStateApproved,
    modalStateAllocated,
    filters,
  ]);
  console.log("searchData", searchData);

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menu = [
    (() => {
      let menuItem = [];
      switch (type) {
        case "Draft":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/draft/view/${selectedRow._id}`, {
                state: { tab: type },
              });
            },
          });
          break;
        case "Advance Request":
          // menuItem.push({
          //   icon: <View />,
          //   label: "View",
          //   onClick: () => {
          //     navigate(`/dashboard/funding/view/${selectedRow?._id}`);
          //     handleClose();
          //   },
          // });
          menuItem.push({
            icon: <Signature />,
            label: "Status",
            onClick: () => {
              setModalStateAdvReq(() => ({
                isModalOpen: true,
                modalTitle: "Approval",
                modalId: selectedRow?._id,
              }));
              handleClose();
            },
          });
          break;
        case "Submitted":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/view/${selectedRow?._id}`, {
                state: { tab: type },
              });
            },
          });
          menuItem.push({
            icon: <Signature />,
            label: "Status",
            onClick: () => {
              setModalStateSubmitted(() => ({
                isModalOpen: true,
                modalTitle: "Approval",
                modalId: selectedRow?._id,
              }));
              handleClose();
            },
          });
          break;
        case "Approved":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/view/${selectedRow?._id}`, {
                state: { tab: type },
              });
            },
          });
          menuItem.push({
            icon: <Signature />,
            label: "Status",
            onClick: () => {
              setModalStateApproved(() => ({
                isModalOpen: true,
                modalTitle: "Approval",
                modalId: selectedRow?._id,
              }));
              handleClose();
            },
          });
          break;
        case "Allocated":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/view/${selectedRow?._id}`, {
                state: { tab: type },
              });
            },
          });
          menuItem.push({
            icon: <Signature />,
            label: "Mark as Funded",
            onClick: () => {
              setModalStateAllocated(() => ({
                isModalOpen: true,
                modalTitle: "Mark as Funded",
                modalId: selectedRow?._id,
              }));
              handleClose();
            },
          });
          break;
        case "Funded":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/view/${selectedRow?._id}`, {
                state: { tab: type },
              });
            },
          });
          break;
        case "Closed":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/view/${selectedRow?._id}`, {
                state: { tab: type },
              });
            },
          });
          break;
        case "Archive":
          menuItem.push({
            icon: <Delete />,
            label: "Delete",
            onClick: () => {
              setModalStateDelete(() => ({
                isModalOpen: true,
                modalTitle: selectedRow.client_name,
                modalId: selectedRow?._id,
              }));
              handleClose();
            },
          });
          break;
        default:
          break;
      }
      (type !== "Archive" && type !== "Funded" && type !== "Closed") &&
      menuItem.push({
        icon: <ArchiveIcon />,
        label: "Reject",
        onClick: () => {
          setModalStateArchive({
            isModalOpen: true,
            name: selectedRow.client_name,
            modalId: selectedRow._id,
          });
          setAnchorEl(null);
        },
      });
      return menuItem;
    })(),
  ];

  const handleCloseArchive = () => {
    setModalStateArchive(() => ({
      isModalOpen: false,
    }));
  };
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };

  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "funding/deleteFunding",
        token: cookies.t,
        params: { id: modalStateDelete?.modalId },
      });
      if (res) {
        toast.success("Funding Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetFundingData();
        getAllFundingStatus();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleArchive = async () => {
    try {
      const res = await patchData({
        endpoint: "funding/UpdateFundingStatus",
        token: cookies.t,
        params: {
          id: modalStateArchive?.modalId,
        },
        data: {
          status: "Archive",
        },
      });
      if (res) {
        toast.success("Funding Archived Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseArchive();
        GetFundingData();
        getAllFundingStatus();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handlePandaDocsStatus = async (data) => {
    console.log("data", data);
    const response = await getPandaApi({
      endpoint: `https://api.pandadoc.com/public/v1/documents/${data?.documentsInvoice?.docInvoiceId}`,
      params: {},
      token: "0752d8cf5fef4dfefa1c9ed82eef75295fda72ec",
    });

    if (response) {
      toast.success("Document Status Refreshed", {
        duration: 1000,
        style: confimationStyles,
      });
    }
    setPandaDocStatus((prev) => {
      const id = data?._id;
      const status = response.status;

      // Check if the ID already exists in the state
      const exists = prev.some((entry) => entry[id] !== undefined);

      if (exists) {
        // Update the existing entry
        return prev.map((entry) =>
          entry[id] !== undefined ? { [id]: status } : entry,
        );
      } else {
        // Add a new entry
        return [...prev, { [id]: status }];
      }
    });
  };
  console.log("pandadocs", pandaDocStatus);
  const columns = [
    {
      field: "client_name",
      headerName: "Client",
      flex: 0.8,
      // renderCell: (params) => {
      //   return <div>{params?.row?.client_name}</div>;
      // },
    },
    {
      field: "obligor_name",
      headerName: "Obligor",
      flex: 0.8,
      // renderCell: (params) => {
      //   return <div>{params?.row?.obligor_name}</div>;
      // },
    },
    {
      field: "supplier_name",
      headerName: "Supplier",
      flex: 0.8,
      // renderCell: (params) => {
      //   return <div>{params?.row?.supplier_name}</div>;
      // },
    },
    ...(type === "Allocated"
      ? [
          {
            field: "funder",
            headerName: "Funder",
            flex: 0.8,
            // renderCell: (params) => {
            //   return <div>{params?.row?.funder && params?.row?.funder}</div>;
            // },
          },
        ]
      : []),
    ...(type === "Draft" || type === "Advance Request"
      ? [
          {
            field: "referance",
            headerName: "Client Ref",
            flex: 0.8,
            renderCell: (params) => {
              return (
                <div>
                  {params?.row?.invoice_number || params?.row?.referance}
                </div>
              );
            },
          },
        ]
      : []),
    {
      field: "cost_value",
      headerName: "Cost Value",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div>
            {formatNumberInternational(parseFloat(params?.row?.cost_value))}
          </div>
        );
      },
    },
    {
      field: "face_value",
      headerName: "Face Value",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div>
            {formatNumberInternational(parseFloat(params?.row?.face_value))}
          </div>
        );
      },
    },
    ...(type === "Advance Request" ||
    type === "Submitted" ||
    type === "Approved" ||
    type === "Allocated" ||
    type === "Funded"
      ? [
          {
            field: "invoice_date",
            headerName: "Invoice Date",
            flex: 0.8,
            renderCell: (params) => {
              return (
                <div>
                  {params?.row?.invoice_date
                    ? moment(params?.row?.invoice_date).format("YYYY-MM-DD")
                    : ""}
                </div>
              );
            },
          },
          {
            field: "due_date",
            headerName: "Due Date",
            flex: 0.8,
            renderCell: (params) => {
              return (
                <div>
                  {params?.row?.due_date &&
                    moment(params?.row?.due_date).format("YYYY-MM-DD")}
                </div>
              );
            },
          },
        ]
      : []),
    ...(type === "Funded"
      ? [
          {
            field: "funding_date",
            headerName: "Funding Date",
            flex: 0.8,
            renderCell: (params) => {
              return (
                <div>
                  {params?.row?.funded_date &&
                    moment(params?.row?.funded_date).format("YYYY-MM-DD")}
                </div>
              );
            },
          },
        ]
      : []),
    ...(type === "Draft"
      ? [
          {
            field: "payment_terms",
            headerName: "Terms",
            flex: 0.8,
            renderCell: (params) => {
              return (
                <div>
                  {params?.row?.payment_terms
                    ? `${params?.row?.payment_terms} Days`
                    : "--"}
                </div>
              );
            },
          },
        ]
      : []),
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.8,
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div
            title={
              params?.row?.transaction_type === "Obligor"
                ? "Factoring/AR"
                : params?.row?.transaction_type
            }
          >
            {params?.row?.transaction_type === "Obligor"
              ? "Factoring/AR"
              : params?.row?.transaction_type}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.status === "Funded" ? "Open" : params?.row?.status}
          </div>
        );
      },
    },
    ...(type === "Advance Request" || type === "Submitted"
      ? [
          {
            field: "documents_status",
            headerName: "Documents Status",
            flex: 1.2,

            renderCell: (params) => {
              const rowId = params.row._id; // Adjust this to match your row ID field
              const documentStatus = pandaDocStatus.find(
                (entry) => entry[rowId] !== undefined,
              );
              return (
                <div className="d-flex align-items-center gap-3">
                  <div
                    title={
                      documentStatus ? documentStatus[rowId] : "Documents Sent"
                    }
                  >
                    {documentStatus ? documentStatus[rowId] : "Documents Sent"}
                  </div>

                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handlePandaDocsStatus(params?.row);
                    }}
                  >
                    <Refresh size="16" />
                  </div>
                </div>
              );
            },
          },
        ]
      : []),

    {
      field: "actions",
      headerName: "Action",
      flex: 0.6,
      renderCell: (params, index) => {
        return (
          <>
            <div>
              <span
                type="button"
                onClick={(event) => handleStatusMenu(event, params.row)}
              >
                <HorizontalMenu />
              </span>
            </div>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              // items={menu?.[0]}
              items={getMenuList()}
            />
          </>
        );
      },
    },
  ];

  const getMenuList = () => {
    const getMenuByRoleDraft = () => {
      if (roleData?.["add/edit"] && roleData?.view) {
        return menu[0];
      } else if (roleData?.view) {
        return [menu[0][0]];
      } else if (roleData?.["add/edit"]) {
        return [menu[0][1]];
      } else {
        return [];
      }
    };
    const getMenuByRoleAdvanceR = () => {
      if (roleData?.["add/edit"] && roleData?.view) {
        return menu[0];
      } else if (roleData?.view) {
        return [menu[0][0]];
      } else if (roleData?.["add/edit"]) {
        return [menu[0][1], menu[0][2]];
      } else {
        return [];
      }
    };

    switch (type) {
      case "Draft":
        return getMenuByRoleDraft();
      case "Advance Request":
        return getMenuByRoleAdvanceR();
      case "Submitted":
        return getMenuByRoleAdvanceR();
      case "Approved":
        return getMenuByRoleAdvanceR();
      case "Allocated":
        return getMenuByRoleAdvanceR();
      case "Funded":
        return getMenuByRoleDraft();
      case "Closed":
        return getMenuByRoleDraft();
      case "Archive":
        return roleData && roleData.delete && menu[0];
      default:
        return [];
    }
  };

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const CustomNoRowsOverlay = memo(() => {
    return loader ? (
      <>
        {" "}
        <div
          className="w-100  d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <span
            className="spinner-border spinner-border-lr ms-2"
            style={{ color: "#0d6efd", width: "50px", height: "50px" }}
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </>
    ) : (
      <>
        <NoContentCard
          title={content[type]?.title}
          subtitle={content[type]?.subtitle}
          type="Pricing"
          classes="d-flex align-items-center"
        />
      </>
    );
  });

  return loader ? (
    <div
      className="w-100  d-flex align-items-center justify-content-center"
      style={{ height: "70vh" }}
    >
      <span
        className="spinner-border spinner-border-lr ms-2"
        style={{ color: "#3b424a", width: "40px", height: "40px" }}
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  ) : (
    <>
      <CustomDatagrid
        rows={fundingData?.[0]?.fundingData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        // tHeight="76vh"
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        // checkRequired
        setSelectedRowIds={setSelectedRowIds}
        onCellClick={(data) => {
          if (type === "Draft") {
            if (data?.field !== "actions" && data?.field !== "__check__") {
              navigate(`/dashboard/funding/draft/view/${data?.row._id}`);
            }
          }
        }}
      />
      {/* {fundingData?.[0]?.pageDetails?.count > 10 && ( */}
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={fundingData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
      {/* )} */}
      <Modal
        isActive={modalStateArchive.isModalOpen}
        onClose={handleCloseArchive}
        onClick={handleArchive}
        title={"Move to Rejected"}
        name={modalStateArchive.name}
        type=""
        description={
          <span>
            Are you sure you want to move the funding for{" "}
            <strong>{modalStateArchive?.name}</strong> to rejected?
          </span>
        }
      />
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title={modalStateDelete?.name}
        name={modalStateDelete.name}
        type="Delete"
      />
    </>
  );
};

export default Table;
