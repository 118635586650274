import React, { useEffect, useState } from "react";
import ExcelJS from "exceljs";
import "./styles.scss";
import toast from "react-hot-toast";
import { errorStyles } from "../../../../../../../assets/styles/components/toast";

const ExcelParser = ({ fileUrl, tabName }) => {

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAndParseExcel = async () => {
      setIsLoading(true);
      const response = await fetch(fileUrl);
      if (!response.ok) {
        // throw new Error("");
        toast.error('Failed to fetch the file.',{style : errorStyles, duration : 1000})
      }

      const arrayBuffer = await response.arrayBuffer();
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);

      let parsedData = {};
      if (tabName) {
        const worksheet = workbook.getWorksheet(tabName);
        if (!worksheet) {
          toast.error(`Tab "${tabName}" not found in the workbook.`,{duration : 1000, style: errorStyles})
        }
        parsedData[tabName] = parseWorksheet(worksheet);
      } else {
        workbook.eachSheet((sheet) => {
          parsedData[sheet.name] = parseWorksheet(sheet);
        });
      }

      setIsLoading(false)
      setData(parsedData);
    };

    if (fileUrl) {
      fetchAndParseExcel();
    }
  }, [fileUrl, tabName]);

  const parseWorksheet = (worksheet) => {
    const rows = [];
    worksheet.eachRow((row, rowIndex) => {
      const rowData = [];
      let lastValue = null;

      row.eachCell({ includeEmpty: true }, (cell, colIndex) => {
        const cellValue = cell.value;


        // Skip the 0th column (first column) if it's empty, null, or undefined
        if (
          colIndex === 1 &&
          (cellValue === "" || cellValue === null || cellValue === undefined)
        ) {
          return; // Skip rendering this cell, but keep the row
        }

        // Handle different types of cell values
        let displayValue = "";
        let isPercentage = false;

        if (cellValue && typeof cellValue === "object") {
          if (cellValue.richText) {
            // Handle rich text (combine all text parts)
            displayValue = cellValue.richText
              .map((textObj) => textObj.text)
              .join("");
          } else if (cellValue.result !== undefined) {
            let resultValue = cellValue.result;
            let isPercentage = false;

            // Check if the value is a percentage
            // 1. Check for percentage format using numFmt
            const numFmt = cell.style && cell.style.numFmt;
            if (numFmt && numFmt.includes("%")) {
              isPercentage = true;
            }

            // 2. If numeric value is less than 1 but greater than 0, treat it as a percentage
            if (!isPercentage && !isNaN(resultValue)) {
              const numberValue = parseFloat(resultValue);
              if (numberValue < 1 && numberValue > 0) {
                isPercentage = true;
              }
            }

            // If it's a percentage, format and append '%'
            if (isPercentage) {
              resultValue = `${(parseFloat(resultValue) * 100).toFixed(2)}%`;
            }

            // Apply parentheses formatting if negative value
            else if (!isPercentage && parseFloat(resultValue) < 0) {
              resultValue = `(${Math.abs(parseFloat(resultValue)).toFixed(2)})`;
            } else {
              // Round the result to 2 decimal places
              if (typeof resultValue === "number" && !isNaN(resultValue)) {
                resultValue = resultValue?.toFixed(2);
              }
            }

            // Set the final formatted value
            displayValue = resultValue;
          } else {
            // If result is undefined, display the cell as a JSON string
            displayValue = JSON.stringify(cellValue);
          }
        } else {
          // For non-object values, ensure they are properly converted to a string
          displayValue =
            cellValue !== null && cellValue !== undefined
              ? cellValue.toString()
              : " ";
        }

        // Check if the value is the same as the last one (for colspan calculation)
        if (
          displayValue === lastValue &&
          !parseFloat(displayValue || !parseInt(displayValue)) &&
          displayValue !== "0"
        ) {
          // Increase the colspan for the current cell and skip rendering this cell
          rowData[rowData.length - 1].colspan += 1;
        } else {
          // Add new cell value with initial colspan of 1
          rowData.push({ value: displayValue, colspan: 1 });
        }

        // Update last value for comparison
        lastValue = displayValue;
      });

      // Push the rowData into rows if it's not an empty row
      if (rowData.length > 0) {
        rows.push(rowData);
      }
    });

    return rows;
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!data || isLoading) {
    return <div className="loading-message">Loading {tabName}...</div>;
  }

  return (
    <div className="excel-parser">
      {Object.entries(data).map(([sheetName, sheetData]) => (
        <div key={sheetName} className="sheet-container">
          {!tabName && <h4 className="sheet-name">{sheetName}</h4>}
          <table className="data-table">
            <tbody>
              {sheetData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className="data-cell"
                      colSpan={cell.colspan}
                    >
                      {cell.value === "0" ? "-" : cell.value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default ExcelParser;
