import { useCookies } from "react-cookie";
import { patchData } from "../../../../../../services";
import CustomButton from "../../../../../atoms/CustomButton";
import style from "./index.module.scss";
import { useState } from "react";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";
import dayjs from 'dayjs';

const AllocatedModal = ({
  modalStateAllocated,
  setModalStateAllocated,
  getAllFundingStatus,
}) => {
  const [cookies] = useCookies(["t"]);
  const [isLoading, setIsLoading] = useState(false);
  const [fundedDate, setFundedDate] = useState();

  const handleApproveFunding = async () => {
    try {
      setIsLoading(true);
      const res = await patchData({
        endpoint: "funding/UpdateFundingStatus",
        token: cookies.t,
        params: {
          id: modalStateAllocated.modalId,
        },
        data: {
          status: "Funded",
          funded_date: fundedDate,
        },
      });
      if (res) {
        toast.success("Funding Funded Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        setIsLoading(false);
      }
      setModalStateAllocated(() => ({
        isModalOpen: false,
      }));
      getAllFundingStatus();
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <div>
        <div className="mb-3">Funding Date</div>
        <div style={{ position: "relative" }} className="mb-3">
          <DateSelector
            onChange={(option) => {
              setFundedDate(option);
            }}
            selectedDate={fundedDate}
            // disablePast
            minDateData={dayjs().subtract(15, 'day').toDate()}
          />
        </div>
      </div>
      <div className={style.buttonStyle}>
        <button
          className="btn-primary btn-lg"
          type="submit"
          style={{ width: "25%" }}
          onClick={() => {
            handleApproveFunding();
          }}
        >
          Confirm Funding Date
          {isLoading && (
            <span
              className="spinner-border spinner-border-sm ms-2 text-light"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </button>
        <CustomButton
          text="Cancel"
          type="btn-outline-dark"
          size="btn-lg"
          handleClick={() => {
            setModalStateAllocated(() => ({
              isModalOpen: false,
            }));
          }}
        />
      </div>
    </>
  );
};
export default AllocatedModal;
