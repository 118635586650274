import FileViewer from "react-file-viewer";
import CustomButton from "../../../../../atoms/CustomButton";
import { Heading } from "../../../../../atoms/Heading";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";
import style from "../index.module.scss";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { DocViewerRenderers, PDFRenderer } from "react-doc-viewer";
import { getData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import moment from "moment";

const SummaryInterest = ({ pricingData, fundedDataById }) => {
  const [cookies] = useCookies(["t"]);
  const [fundingData, setFundingData] = useState();
  const [excelData, setExcelData] = useState(null);
  const [excelTypeData, setExcelTypeData] = useState({
    fileType: "",
    file: "",
  });
  const [currentIndex, setCurrentIndex] = useState({
    docIndex: 0,
    fileType: "",
    imagesType: "", // Add imagesType to the state
  });
  const [paymentDetails, setPaymentDetails] = useState();

  console.log("paymentDetails", paymentDetails);

  useEffect(() => {
    if (fundedDataById?.documents) {
      setFundingData(fundedDataById);
      setPaymentDetails(fundedDataById?.paymentHistory);
    }
    if (fundedDataById?.documents) {
      // const groupedDocuments = fundedDataById?.documents.reduce((acc, doc) => {
      //   if (!acc[doc?.imagesType]) {
      //     acc[doc?.imagesType] = [];
      //   }
      //   acc[doc?.imagesType].push(doc);
      //   return acc;
      // }, {});
      // setDocumentsGroupItem(groupedDocuments);
      // const firstImagesType = Object.keys(groupedDocuments)[0];
      // if (firstImagesType) {
      //   setCurrentIndex({
      //     docIndex: 0, // Set to the first document
      //     fileType: groupedDocuments[firstImagesType][0]?.imagesType,
      //     imagesType: firstImagesType, // Set to the first imagesType
      //   });
      // }
      setCurrentIndex({
        docIndex: 0, // Set to the first document
        fileType: fundedDataById?.documents?.[0]?.file,
      });
    }
  }, [fundedDataById]);

  const handleIndexFile = (index, doc) => {
    setCurrentIndex(() => ({
      docIndex: index,
      fileType: doc?.file,
      // imagesType: imagesType, // Set the imagesType when a file is clicked
    }));

    const type = getFileType(doc?.file);
    if (type.toLowerCase() === "xls" || type.toLowerCase() === "xlsx") {
      setExcelTypeData(() => ({
        fileType: type,
        file: doc?.file,
      }));
    }
  };
  const xlsxFileDownload = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
      });
      setExcelData(sheetData);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (excelTypeData?.file) {
      xlsxFileDownload(excelTypeData.file);
    }
  }, [excelTypeData]);

  useEffect(() => {
    if (fundingData?.documents?.[0]) {
      const fileTypeAtIndex0 = getFileType(fundingData.documents[0]?.file);
      if (fileTypeAtIndex0 === "xls" || fileTypeAtIndex0 === "xlsx") {
        setExcelTypeData(() => ({
          fileType: fileTypeAtIndex0,
          file: fundingData.documents[0]?.file,
        }));
      }
    }
  }, [fundingData?.documents, currentIndex]); // Run this effect when fundingData changes

  const getFileType = (url) => {
    const extension = url?.split(".")?.pop();
    return extension?.toLowerCase();
  };
  // {Object?.keys(documentsGroupItem)?.map((imagesType, index) => (
  //   <div key={index}>
  //     {/* <h3>{imagesType}</h3> */}
  //     <div className={style.documentsButtonWrap}>
  //       {documentsGroupItem?.[imagesType]?.map((doc, idx) => (
  //         <button
  //           className={"btn-primary btn-sm"}
  //           style={{}}
  //           onClick={() => {
  //             handleIndexFile(idx, doc, imagesType);
  //             setExcelData();
  //           }}
  //         >
  //           {index + 1}
  //         </button>
  //       ))}
  //     </div>
  //   </div>
  // ))}

  return (
    <div className={style.bothsidewrap}>
      <div className={style.fundedViewleftWrap}>
        <div>
          <table className="w-100 border-collapse">
            <thead>
              <tr className="bg-gray">
                <th colSpan="4" className="border p-2 text-left">
                  Payment
                </th>
              </tr>
              <tr className="bg-gray">
                <th className="border p-2 text-left">Payment Amount</th>
                <th className="border p-2 text-left">Currency</th>
                <th className="border p-2 text-left">Date</th>
              </tr>
            </thead>
            <tbody>
              {paymentDetails?.map((item) => {
                return (
                  <tr>
                    <td className="border p-2">{item?.paymentAmount}</td>
                    <td className="border p-2">{item?.currency}</td>
                    <td className="border p-2">
                      {moment(item?.paydate).format("YYYY-MM-DD")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div>
          <Heading className="HeadingSubStyle mt-3">Accrued Date</Heading>
          <label>As of Date</label>
          <div style={{ position: "relative", width: "100%" }}>
            <DateSelector
              placeholder="Select Date"
              onChange={(option) => {
                console.log("option", option);
              }}
            />
          </div>
        </div>
        <div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Pricing Information</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={style.label}>Name</td>
                <td className={style.value}>{pricingData?.name}</td>
              </tr>
              <tr>
                <td className={style.label}>Description</td>
                <td className={style.value}>{pricingData?.description}</td>
              </tr>
              <tr>
                <td className={style.label}>Top Rate</td>
                <td className={style.value}>{pricingData?.top_rate}</td>
              </tr>
              <tr>
                <td className={style.label}>Base Rate</td>
                <td className={style.value}>
                  {pricingData?.base_rate_name || " "}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Days</td>
                <td className={style.value}>{pricingData?.days}</td>
              </tr>
              <tr>
                <td className={style.label}>Advance Rate</td>
                <td className={style.value}>{pricingData?.advance_rate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={style.fundedViewRightWrap}>
        <div className={style.iframeStyle}>
          <div className={style.documentsWrap}>
            {fundedDataById?.documents?.map((item, index) => {
              return (
                <>
                  <button
                    className={`${
                      currentIndex?.docIndex === index
                        ? "btn-primary"
                        : "btn-outline-dark"
                    } btn-sm`}
                    style={{}}
                    onClick={() => {
                      handleIndexFile(index, item);
                      setExcelData();
                    }}
                  >
                    {index + 1}
                  </button>
                </>
              );
            })}
          </div>

          <div className="mt-2">
            {currentIndex.fileType && (
              <>
                {(getFileType(currentIndex?.fileType) === "png" ||
                  getFileType(currentIndex?.fileType) === "jpeg") && (
                  <img
                    src={currentIndex?.fileType}
                    alt="images"
                    width={"100%"}
                    height={"auto"}
                  />
                )}
                {getFileType(currentIndex?.fileType) === "docx" && (
                  <FileViewer
                    fileType="docx"
                    filePath={currentIndex?.fileType}
                    pluginRenderers={DocViewerRenderers}
                  />
                )}
                {getFileType(currentIndex?.fileType) === "pdf" && (
                  <FileViewer
                    fileType="pdf"
                    filePath={currentIndex?.fileType}
                    pluginRenderers={PDFRenderer}
                  />
                )}
                {excelData && (
                  <div className={style.excelBoadyWrap}>
                    <table border="1">
                      <thead>
                        <tr>
                          {excelData[0].map((header, i) => (
                            <th className={style.headerStyleCell} key={i}>
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {excelData.slice(1).map((row, i) => (
                          <tr key={i}>
                            {row.map((cell, j) => (
                              <td className={style.columnCellStyle} key={j}>
                                {cell}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SummaryInterest;
