import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { getData } from "../../../../../services";
import { Heading } from "../../../../atoms/Heading";
import PageCard from "../../../../atoms/PageCard";
import style from "../index.module.scss";
import { fontSize, width } from "@mui/system";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import moment from "moment";
import ViewMore from "../../../../atoms/ViewMore";
import { formatNumberInternational } from "../../../../../helpers/capatalizeLetters";

export const LabelDisplay = ({
  label,
  value,
  className,
  modalStyles = false,
  valueStyles,
  divStyles,
  labelStyles,
}) => {
  return (
    <div
      className={`d-flex ${className}`}
      style={{ width: modalStyles ? "90%" : "50%", ...divStyles }}
    >
      {label && (
        <label
          className={style.label_view}
          style={{ width: "35%", ...labelStyles }}
        >
          {label}
        </label>
      )}
      <div
        className={style.label_description}
        style={{ width: "48%", ...valueStyles }}
      >
        {value}
      </div>
    </div>
  );
};

const ViewCounterparty = ({ type = "" }) => {
  const { id } = useParams();
  const [counterData, setCounterData] = useState([]);
  const [SONames, setSONames] = useState();
  const [cookies] = useCookies(["t"]);
  const [duesData, setDuesData] = useState([]);

  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setCounterData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getSupplierAndObligorNames = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getAllNamesSupplierAndObligorById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setSONames(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getDueData = async (fundingData) => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingByClientId",
        params: {
          id: fundingData._id,
          status: "Funded",
          trans_type: fundingData?.counter_type,
        },
        token: cookies.t,
      });
      const totalCostValue = res?.data.reduce((sum, item) => {
        return parseFloat(sum) + parseFloat(item.cost_value);
      }, 0);
      setDuesData(totalCostValue);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (counterData) {
      getDueData(counterData);
    }
  }, [counterData]);

  useEffect(() => {
    if (id) {
      getSupplierAndObligorNames();
      getCounterDataById();
    }
  }, [id]);

  return (
    <>
      {/* <div
        style={{
          marginBottom: "20px",
          width: "100%",
          borderBottom: "2px solid #D5D5D5",
          paddingBottom: "10px",
        }}
      >
        <span className={style.type_header_styles}>
          {counterData.counter_type}
        </span>
      </div> */}
      <LabelDisplay
        label="Client"
        value={
          counterData.counter_type === "Client"
            ? counterData.counter_name
            : counterData.client_name
        }
        className={style.client_type_main}
      />
      <Heading className="addHeaderStyle fs-20 mb-3">
        Counterparty Information
      </Heading>
      <div
        className="w-100 d-flex flex-wrap mb-3"
        style={{ columnGap: "0%", rowGap: "10px" }}
      >
        <LabelDisplay label="Name" value={counterData.counter_name} />
        <LabelDisplay label="Address" value={counterData.address} />
        <LabelDisplay label="TaxID" value={counterData.tax_id} />
        <LabelDisplay label="Type" value={counterData.counter_type} />
        <LabelDisplay label="Verified" value={counterData.verified} />
        <LabelDisplay></LabelDisplay>
        <LabelDisplay
          label="Notes"
          value={<ViewMore data={counterData?.notes} /> || "--"}
          valueStyles={{ width: "72.5%" }}
          divStyles={{ width: "100%" }}
          labelStyles={{ width: "17.5%" }}
        />
      </div>
      <Heading className="addHeaderStyle fs-20 mb-3">
        Credit Information
      </Heading>
      <div
        className="w-100 d-flex flex-wrap mb-3"
        style={{ columnGap: "0%", rowGap: "10px" }}
      >
        <LabelDisplay
          label="Counterparty Type"
          value={counterData.counter_type}
        />
        <LabelDisplay
          label="Internal Credit Limit"
          value={formatNumberInternational(counterData.credit_limit)}
        />
        <LabelDisplay label="Credit Used" value={duesData} />
        <LabelDisplay
          label="Credit Remaining"
          value={formatNumberInternational(parseFloat(counterData.credit_limit) - parseFloat(duesData))}
        />
        <LabelDisplay label="Sector" value={counterData.gics_sector} />
        <LabelDisplay label="Industry" value={counterData.gics_industry} />
        <LabelDisplay label="Geography" value={counterData.geography} />
        <LabelDisplay label="Currency" value={counterData.primary_currency} />
      </div>
      {type === "Related Parties Info" && (
        <>
          <Heading className="addHeaderStyle fs-20 mb-3">
            Insurance Limit Information
          </Heading>
          <div
            className="w-100 d-flex flex-wrap mb-3"
            style={{ columnGap: "0%", rowGap: "10px" }}
          >
            <LabelDisplay
              label="Limit"
              value={
                counterData?.insuranceDetails?.[0]?.limit
                  ? counterData?.insuranceDetails?.[0]?.limit
                  : "--"
              }
            />
            <LabelDisplay
              label="End Date"
              value={
                counterData?.insuranceDetails?.[0]?.end_date
                  ? moment(counterData?.insuranceDetails?.[0]?.end_date).format(
                      "YYYY-MM-DD",
                    )
                  : "--"
              }
            />
            <LabelDisplay
              label="Type"
              value={
                counterData?.insuranceDetails?.[0]?.limit_type
                  ? counterData?.insuranceDetails?.[0]?.limit_type
                  : "--"
              }
            />
          </div>
          <Heading className="addHeaderStyle fs-20 mb-3">
            Related Parties Info
          </Heading>
          <div
            className="w-100 d-flex flex-wrap mb-3"
            style={{ columnGap: "0%", rowGap: "10px" }}
          >
            <LabelDisplay
              label="Obligor"
              value={
                SONames && SONames.obligor && SONames.obligor.length > 0
                  ? SONames.obligor
                      .map((obligor) => (
                        <span key={obligor.id}>
                          <a
                            href={`/dashboard/counterparty/view/${obligor.id}`} // Replace with your route structure
                            style={{
                              color: "#1B242C",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {obligor.name}
                          </a>
                        </span>
                      ))
                      .reduce((prev, curr) => [prev, ", ", curr]) // Adds a comma between names
                  : "None"
              }
            />

            {/* <LabelDisplay
              label="Supplier"
              value={
                SONames && SONames?.supplier && SONames?.supplier.length > 0
                  ? SONames?.supplier?.join(",")
                  : "None"
              }
            /> */}

            <LabelDisplay
              label="Supplier"
              value={
                SONames && SONames?.supplier && SONames?.supplier.length > 0
                  ? SONames?.supplier
                      .map((supplier) => (
                        <span key={supplier.id}>
                          <a
                            href={`/dashboard/counterparty/view/${supplier.id}`} // Replace with your route structure
                            style={{
                              color: "#1B242C",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {supplier.name}
                          </a>
                        </span>
                      ))
                      .reduce((prev, curr) => [prev, ", ", curr]) // Adds a comma between names
                  : "None"
              }
            />
            <LabelDisplay
              label="Signers"
              value={
                counterData &&
                counterData.signerDetails &&
                counterData.signerDetails
                  .map((item, index) => {
                    return (
                      (index !== 0 ? ", " : "") +
                      item.first_name +
                      " " +
                      item.last_name
                    );
                  })
                  .join("")
              }
            />
            <LabelDisplay
              label="Brokers"
              value={
                counterData &&
                counterData.brokerDetails &&
                counterData.brokerDetails
                  .map((item, index) => {
                    return (index !== 0 ? ", " : "") + item.broker_name;
                  })
                  .join("")
              }
            />
          </div>
        </>
      )}
      {type === "Internal Info" && (
        <>
          <Heading className="addHeaderStyle fs-20 mb-3">Internal Info</Heading>
          <div
            className="w-100 d-flex flex-wrap mb-3"
            style={{ columnGap: "0%", rowGap: "10px" }}
          >
            <LabelDisplay
              label="Agreement Date"
              value={
                counterData.agreement_date
                  ? moment(counterData.agreement_date).format("YYYY-MM-DD")
                  : "--"
              }
            />
            <LabelDisplay
              label="Financing Entity"
              value={
                counterData.agreement_counter
                  ? counterData.agreement_counter
                  : "--"
              }
            />
            <LabelDisplay label="Template Funding Reference" value={"--"} />
            <LabelDisplay label="S&P Number" value={counterData.sp_number} />
            <LabelDisplay label="D&B Number" value={counterData.db_number} />
            <LabelDisplay
              label="Currency"
              value={counterData.primary_currency}
            />
          </div>
        </>
      )}
    </>
  );
};
export default ViewCounterparty;
