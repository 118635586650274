import React from "react";

const Close = ({size = '14', classes}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      className={classes}
    >
      <path
        d="M0.577681 12.2433L5.82183 6.99918L0.577681 1.75501C0.425881 1.59784 0.34189 1.38734 0.343781 1.16884C0.345681 0.950339 0.433323 0.74133 0.587831 0.586822C0.74234 0.432322 0.951348 0.34468 1.16985 0.34278C1.38834 0.34088 1.59885 0.42488 1.75601 0.576672L7.00016 5.82085L12.2443 0.576672C12.3212 0.49708 12.4132 0.433597 12.5148 0.389922C12.6165 0.346247 12.7258 0.323264 12.8365 0.322297C12.9472 0.321339 13.0569 0.342422 13.1593 0.384322C13.2617 0.426222 13.3547 0.488105 13.433 0.566347C13.5112 0.644589 13.5732 0.73763 13.615 0.840047C13.6569 0.942464 13.678 1.05219 13.6771 1.16284C13.6761 1.27349 13.6531 1.38284 13.6094 1.48451C13.5657 1.58618 13.5022 1.67814 13.4227 1.75501L8.1785 6.99918L13.4227 12.2433C13.5022 12.3202 13.5657 12.4122 13.6094 12.5138C13.6531 12.6155 13.6761 12.7248 13.6771 12.8355C13.678 12.9462 13.6569 13.0558 13.615 13.1583C13.5732 13.2607 13.5112 13.3538 13.433 13.432C13.3547 13.5103 13.2617 13.5721 13.1593 13.614C13.0569 13.6559 12.9472 13.677 12.8365 13.676C12.7258 13.6751 12.6165 13.6521 12.5148 13.6084C12.4132 13.5648 12.3212 13.5013 12.2443 13.4217L7.00016 8.17751L1.75601 13.4217C1.59885 13.5735 1.38834 13.6574 1.16985 13.6556C0.951348 13.6537 0.74234 13.566 0.587831 13.4115C0.433323 13.257 0.345681 13.048 0.343781 12.8295C0.34189 12.611 0.425881 12.4005 0.577681 12.2433Z"
        fill="black"
      />
    </svg>
  );
};

export default Close;
