import { useField,useFormikContext } from "formik";
import "./style.scss";
import { useState } from "react";
import Error from "../../atoms/Error";
import Info from "../Info";

const TextInput = ({
  label,
  icon,
  InputHeight = "small",
  info,
  customBlur,
  blurError,
  width,
  isDisabled,
  required,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldTouched } = useFormikContext();
  const [placeholder, setPlaceholder] = useState(props.customholder);

  const handleFocus = () => {
    setPlaceholder(
      props.customholder.substring(0, 5) === "Enter"
        ? props.customholder.substring(5)
        : props.customholder,
    );
  };

  const handleBlur = (event) => {
    field.onBlur(event);
    if(props.onBlur){
      props.onBlur(event);
    }
    if (event.target.value === "") {
      setPlaceholder(props.customholder);
    }
    if (customBlur) customBlur();
  };
  

  return (
    <>
      {label && <label htmlFor={props.id || props.name}>{label}</label>}
      <div
        className={`d-flex align-center input-container input_icon_wrap`}
        style={{ width: width }}
      >
        {icon && <div className={`input_icon`}>{icon}</div>}
        <input
          {...field}
          {...props}
          className={`${
            (meta.touched && meta.error) || blurError ? "error-input " : ""
          }${
            InputHeight === "small" ? "primary-input-small" : "primary-input"
          } `}
          placeholder=""
          onFocus={handleFocus}
          onBlur={(e) => {handleBlur(e); setFieldTouched(props.name, true);}}
          disabled={isDisabled}
        />
        <label
          class={`${
            InputHeight === "small"
              ? "input-placeholder-small"
              : "input-placeholder"
          } ${required ? 'required-field' : ''}` }
        >
          {placeholder}
        </label>
      </div>
 
      {console.log('info',meta.touched && meta.error && blurError)}
      {console.log('meta info',meta)}



      {(meta.touched && meta.error) || blurError ? (
        // <div
        //   className="error-input-message"
        //   style={{ marginTop: InputHeight === 'small' ? '4px' : '8px' }}
        // >
        //   {meta.error}
        // </div>
        <Error error={meta.error || blurError} type={InputHeight} />
      ) : null}
      {(info || !(meta.touched && meta.error && blurError)) ?  <Info info={info} /> : null}

    </>
  );
};

export default TextInput;
