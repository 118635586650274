import { fontSize, fontWeight, height, minHeight, padding } from "@mui/system";

export const customDropdownStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    minheight: '46px',
    height: '46px',
    outline: state.isFocused ? 'none' : 'none',
    // backgroundColor: state.isFocused ? '#ffffff' : '#F4F4F4', // Change to your focused background color
    border: state.isFocused
      ? '1px solid #146ef5 !important'
      : '1px solid #dee3e7 !important', // Change to your focused border color and width
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '6px 14px',
    fontSize: '14px',
    fontWeight: 500,
    paddingBottom: '4px',
    paddingTop: '10px',
    // ":hover": {
    //   border: "1px solid #3D9BE9", // Change to your hover border color and width
    // },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    minheight: '46px',
    padding: 0,
    paddingTop :  '7px',
    fontSize: '16px',
    fontWeight : '500'
  }),
  inputContainer: (provided: any) => ({
    ...provided,
    minHeight: '46px',
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    fontSize: '12px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? '#F6F9FF'
      : state.isSelected
      ? '#F6F9FF'
      : provided.backgroundColor,
    color: (typeof state.data?.label === 'string' && state.data.label.startsWith('Add new'))
      ? '#1B242C'
      : state.isSelected 
      ? '#146EF5' 
      : '#1B242C',
    fontWeight: (typeof state.data?.label === 'string' && state.data.label.startsWith('Add new')) ? '600' : '400',
    borderBottom: (typeof state.data?.label === 'string' && state.data.label.startsWith('Add new')) ? '1px solid #DEE3E7' : 'none',
    ':hover': {
      backgroundColor: state.isFocused
        ? '#F6F9FF'
        : state.isSelected
        ? '#F6F9FF'
        : '#F4F4F4',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    // maxHeight: "200px", // Adjust this value based on your requirement
    overflowY: 'auto',
    zIndex: 10,
    minWidth: '200px',
    border: '1px solid var(--Dark-6, #DEE3E7)',
    borderRadius: '6px',
    boxShadow: '0px 4px 4px 0px #0000001A',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: 'none',
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    color: '#1B242C !important',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    color: 'hsl(0,0%,0%)',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: 'hsl(0,0%,0%)',
    paddingTop: '3px !important',
  }),
  singleValue : (provided) => ({
    ...provided,
    paddingLeft : '-1px',
    marginLeft : '0px',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: '#F6F9FF', // Set your desired color here
    borderRadius: '6px',
    border: '1px solid #efefef',
    padding: '5px',
    paddingTop: '0px',
    paddingBottom: '0px',
  }),

  multiValueLabel: (provided: any) => ({
    ...provided,
    fontSize: '15px',
    fontWeight: 500,
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    backgroundColor: '#F6F9FF', // Set the background color for the remove button
    cursor: 'pointer', // Add a pointer cursor to indicate it's clickable
    color: '#6F767E',
    borderRadius: '6px',
    ':hover': {
      backgroundColor: '#F6F9FF', // Set the background color on hover
    },
  }),
};

export const filterDropdownStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    minheight: '46px',
    outline: state.isFocused ? 'none' : 'none',
    backgroundColor: '#ffffff', // Change to your focused background color
    border: state.isFocused
      ? '1px solid #3D9BE9 !important'
      : '1px solid #C0C4C9', // Change to your focused border color and width
    borderRadius: '60px',
    boxShadow: 'none',
    paddingLeft: '15px',
    fontSize: '14px',
    fontWeight: 500,
    minWidth: '180px',
    // ":hover": {
    //   border: "1px solid #3D9BE9", // Change to your hover border color and width
    // },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    minheight: '46px',
    padding: 0,
  }),
  inputContainer: (provided: any) => ({
    ...provided,
    minHeight: '46px',
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    fontSize: '12px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? '#F8F8F8'
      : state.isSelected
      ? '#FFFFFF'
      : provided.backgroundColor,
    color: provided,
    ':hover': {
      backgroundColor: state.isFocused
        ? '#F8F8F8'
        : state.isSelected
        ? '#F4F4F4'
        : '#F4F4F4',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    // maxHeight: "200px", // Adjust this value based on your requirement
    overflowY: 'auto',
    zIndex: 10,
    minWidth: '200px',
    border: 'none',
    borderRadius: '16px',
    boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.125)',
    fontSize: '14px',
    fontWeight: 500,
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: 'none',
    color: '#000',
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: '#fff', // Set your desired color here
    borderRadius: '5px',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    backgroundColor: '#fff', // Set the background color for the remove button
    cursor: 'pointer', // Add a pointer cursor to indicate it's clickable
    ':hover': {
      backgroundColor: '#fff', // Set the background color on hover
    },
  }),
};

export const errorStyles = {
  control: (provided, state) => ({
    ...provided,
    minheight: '46px',
    outline: state.isFocused ? 'none' : 'none',
    backgroundColor: '#c5343412',
    border: '1px solid var(--Red, #c53434) !important',
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '6px 14px',
    fontSize: '14px',
    fontWeight: 500,
    paddingBottom: '4px',
    paddingTop: '10px',
  }),
};

export const multiSelectStyles = {
  multiValueLabel: (provided: any) => ({
    ...provided,
    padding:"0 3px !important",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "2px !important",
    paddingTop: '20px !important',
    paddingLeft: '12px !important',
    gap:"5px !important",
  }),
  control:(provided:any)=>({
    ...provided,
    minHeight:"46px",
    height:"auto !important",
    border: "1px solid #dee3e7 !important"
  })
};



export const MuiCalenderStyles = () => {};
