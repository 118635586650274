import { Heading } from "../../../../atoms/Heading";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import SelectDropdown from "../../../../atoms/Dropdown";
import { useEffect, useState } from "react";
import { getData } from "../../../../../services";
import { useCookies } from "react-cookie";
import Supplier from "./supplier";
import Obligor from "./obligor";
import Client from "./client";
import MyDropdown from "../../../../atoms/MyDropdown";
import { getCurrencyRate } from "../../../../../services/currencyConvertor";

const AddFunding = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const [selectedClient, setSelectedClient] = useState({
    clientName: "",
    clientId: "",
  });
  const [clientData, setClientData] = useState([]);
  const [supplierObligorData, setSupplierObligorData] = useState([]);
  const [selectedTransactionType, setSelectedTransactionType] = useState({
    selectedType: "",
  });
  const [transactionTypeData, setTransactionTypeData] = useState([]);
  const [creditData, setCreditData] = useState([])
  const [currencyConversion, setCurrencyConversion] = useState(null);

  const getClientData = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getAllCountersList",
        token: cookies.t,
        params: {
          conterparty_type: ["Client"],
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };



  useEffect(() => {
    getClientData();

    const fetchCurrencyRate = async () => {
      try {
        const res = await getCurrencyRate({ date: new Date() });
        setCurrencyConversion(res);
      } catch (error) {
        console.error("Error fetching currency rate:", error);
      }
    };

    fetchCurrencyRate(); // Call the async function
  }, []);

  const getTranscationType = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/clientSupplierAndObligor",
        token: cookies.t,
        params: {
          id: selectedClient?.clientId,
        },
      });
      const updatedData = res?.data.flatMap((item) => {
        if (item?.tag === "Obligor") {
          return { ...item, tag: "Factoring/AR" };
        } else if (item?.tag === "Client") {
          const newTags = [];
          newTags.push({ ...item, tag: "Note" });
          newTags.push({ ...item, tag: "Revolver" });
          if (item?.transaction_type) {
            newTags.push({ ...item, tag: item.transaction_type });
          }
          return newTags;
        } else if (item?.tag === "Supplier") {
          const newTags = [];
          newTags.push({ ...item, tag: "Supplier Credit" });
          newTags.push({ ...item, tag: "Supplier Credit-1" });
          return newTags;
        }
        return item;
      });
      const uniqueTags = [...new Set(updatedData.map((item) => item.tag))];
      const tempdata = uniqueTags.map((tag) => ({ label: tag, value: tag }));
      console.log("temp", tempdata);
      setTransactionTypeData(tempdata);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (selectedClient?.clientId) {
      getTranscationType();
    }
  }, [selectedClient?.clientId]);

  useEffect(() => {
    if (selectedClient.clientId && selectedTransactionType.selectedType) {
      getSupplierAndObligor();
    }
  }, [selectedClient, selectedTransactionType]);

  const getSupplierAndObligor = async (value) => {
    try {
      const res = await getData({
        endpoint: "couterParty/getSupplierAndObligorById",
        token: cookies.t,
        params: {
          client_id: selectedClient?.clientId,
          counterparty_type: selectedTransactionType.selectedType,
        },
      });
      const tempData = res?.data?.map((item) => {
        return {
          label: item?.counter_name,
          value: item?._id,
        };
      });

      setSupplierObligorData([
        ...tempData,
        { label: selectedClient.clientName, value: selectedClient.clientId },
      ]);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedTransactionType.selectedType === "Note" ||
          selectedTransactionType.selectedType === "Revolver") {
        const res = await getData({
          endpoint: "couterParty/clientCreditLeft",
          token: cookies.t,
          params: {
            client_id: selectedClient?.clientId,
            trans_type: selectedTransactionType.selectedType,
          }
        });
        // Handle the response as needed
        setCreditData(res?.data)
      }
    };
  
    fetchData();
  }, [selectedTransactionType]);

  return (
    <>
      <Breadcrumb labels={["Funding", "Add Funding"]} />
      <Heading className="addHeaderStyle">Add Funding</Heading>
      <div className="row">
        <div className="col-3 mt-2">
          <SelectDropdown
            data={clientData}
            placeholder="Client Name"
            onChange={(option) => {
              setSelectedClient(() => ({
                clientName: option.label,
                clientId: option.value,
              }));
              setSelectedTransactionType(() => ({
                selectedType: "",
              }));
            }}
            format="all"
            selectedValue={selectedClient.clientId}
            required
          />
        </div>
        <div className="col-3 mt-2">
          <MyDropdown
            data={transactionTypeData}
            placeholder="Transaction Type"
            onChange={(option) => {
              setSelectedTransactionType(() => ({
                selectedType: option,
              }));
            }}
            selectedValue={selectedTransactionType.selectedType}
            required
          />
        </div>
      </div>

      {(selectedTransactionType.selectedType === "Supplier Credit" ||
        selectedTransactionType.selectedType === "Supplier Credit-1") && (
        <Supplier
          supplierObligorData={supplierObligorData}
          clientDetails={selectedClient}
          selectedTransactionType={selectedTransactionType}
          currencyConversion={currencyConversion}
        />
      )}
      {selectedTransactionType.selectedType === "Factoring/AR" && (
        <Obligor
          supplierObligorData={supplierObligorData}
          clientDetails={selectedClient}
          currencyConversion={currencyConversion}
          selectedTransactionType={selectedTransactionType}
        />
      )}
      {(selectedTransactionType.selectedType === "Note" ||
        selectedTransactionType.selectedType === "Revolver") && (
        <Client
          clientDetails={selectedClient}
          selectedTransactionType={selectedTransactionType}
          creditData={creditData}
          currencyConversion={currencyConversion}
        />
      )}
    </>
  );
};
export default AddFunding;
