import React from "react";
import style from "./styles.module.scss";
import Upload from "../../../../../icons/Upload";
import Modal from "../../../../../atoms/modals/Modal";
import MyDropdown from "../../../../../atoms/MyDropdown";
import CustomButton from "../../../../../atoms/CustomButton";
import ImageSelector from "../../../../../atoms/ImageSelector/ImageSelector";
import { useState } from "react";
import { postFormData } from "../../../../../../services";
import { Obj2FormData } from "../../../../../../helpers/capatalizeLetters";
import { height } from "@mui/system";

const UploadSupportingDocument = ({
  handleMultipleImages,
  ImagestypeData,
  clientDetails,
  cookie,
}) => {
  const [fileType, setFileType] = useState();
  const [imagesName, setImagesName] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [modalImageType, setModalImagesType] = useState({
    isModalOpen: false,
    modalTitle: "",
  });

  const handleClick = () => {
    setModalImagesType(() => ({
      isModalOpen: true,
      modalTitle: "Select File Type:",
    }));
  };

  const handleClosrModalImagesType = () => {
    setModalKey(prev => prev + 1)
    setModalImagesType(() => ({
      isModalOpen: false,
      modalTitle: "",
    }));

    setImagesName(null)
    setFileType(null)
  };
  return (
    <>
      <div className={style.input} onClick={handleClick}>
        <label htmlFor="" className="required-field">
          <Upload /> Upload Supporting Documents{" "}
        </label>
      </div>
      <Modal
        isActive={modalImageType.isModalOpen}
        key={modalKey}
        title={modalImageType.modalTitle}
        onClose={handleClosrModalImagesType}
        width="400px"
        extrastyle={{overflow: 'visible'}}
        bodyStyles={{overflow : 'visisble'}}
      >
        <div className={style.modalStyleButton}>
          <MyDropdown
            data={ImagestypeData}
            onChange={(option) => {
              setFileType(option);
              // handleMultipleImages(imagesName, option);
              // setModalImagesType(() => ({
              //   isModalOpen: false,
              // }));
            }}
            placeholder="Select File Type"
            menuPlacement={'bottom'}
          />
          <div className="mt-3">
            <ImageSelector
              placeholder="Select Document"
              type="start"
              handleFile={async (file) => {
                const formData = new FormData();
                formData.append("files", file, file?.name);
                const dataToSend = {
                  files: formData,
                  client_id: clientDetails?.clientId,
                  client_name: clientDetails?.clientName,
                };
                setImageLoading(true);
                const res = await postFormData({
                  endpoint: "funding/uploadFile",
                  token: cookie.t,
                  // data: Obj2FormImage(formData),
                  data: Obj2FormData(dataToSend, formData),
                });
                setImageLoading(false);
                setImagesName(res?.data?.data?.[0]);
                setModalImagesType(() => ({
                  isModalOpen: true,
                  modalTitle: "Select File Type:",
                }));
              }}
              isLoading={imageLoading}
            //   multiple
              //   touched={touched.client_supporting_docs}
              //   errors={errors.client_supporting_docs}
            />
          </div>

          <div className="mt-3">
            {/* <CustomButton
              text="Confirm Selection"
              type="btn-primary"
              handleClick={() => {
                handleMultipleImages(imagesName, fileType);
                setModalImagesType(() => ({
                  isModalOpen: false,
                }));
              }}
            /> */}
            <button className="btn-lg btn-primary px-4" 
              type="button"
              style={{width : '100%'}}
              onClick={() => {
                handleMultipleImages(imagesName, fileType);
                handleClosrModalImagesType()
              }}
              disabled={!imagesName || !fileType || imageLoading}
            >
              Confirm Selection
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UploadSupportingDocument;
