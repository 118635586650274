import { useEffect, useState } from "react";
import { getData, patchData } from "../../../../../services";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { Heading } from "../../../../atoms/Heading";
import style from "./index.module.scss";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import MyDropdown from "../../../../atoms/MyDropdown";
import { Field, Formik } from "formik";
import moment from "moment";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import * as Yup from "yup";
import TextInput from "../../../../atoms/TextInput";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import { v4 as uuidv4 } from "uuid";
import View from "../../../../icons/View";
import Modal from "../../../../atoms/modals/Modal";
import * as XLSX from "xlsx";
import FileViewer from "react-file-viewer";
import { DocViewerRenderers, PNGRenderer } from "react-doc-viewer";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import CustomMenu from "../../../../molecules/CustomMenu";
import { useLocation } from "react-router-dom";
import { removeTimestamp } from "../../../../../helpers/removeTimestamp";
import { formatNumberInternational } from "../../../../../helpers/capatalizeLetters";
const ViewDraftFunding = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t"]);
  const location = useLocation();
  const { id } = useParams();
  const [fundingData, setFundingData] = useState({});
  const [clientData, setClientData] = useState({});
  const [signerData, setSignerData] = useState([]);
  const [selectedSigner, setSelectedSigner] = useState();
  const [selectedSignerById, setSelectedSignerId] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState();
  const [modalStateDocs, setModalStateDocs] = useState({
    isModalOpen: false,
    data: "",
    modalTitile: "",
  });

  const [excelData, setExcelData] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  console.log("selectedSigner", selectedSigner?.length);

  const getFundingDataById = async () => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setFundingData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getClientDataById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: fundingData?.client_id,
        },
      });
      const tempSigner = res?.data?.signerDetails?.map((item) => {
        return {
          label: item?.first_name + " " + item?.last_name + " - " + item?.email,
          value: item?._id,
        };
      });
      setSignerData(tempSigner);
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (id) {
      getFundingDataById();
    }
  }, [id]);

  useEffect(() => {
    if (fundingData?.client_id) {
      getClientDataById();
    }
  }, [fundingData?.client_id]);

  const handleSubmit = async () => {
    const dataToSend = {
      client_id: fundingData?.client_id,
      client_name: fundingData?.client_name,
      currency: fundingData?.currency,
      documents: fundingData?.documents,
      due_date: fundingData?.due_date,
      face_value: fundingData?.face_value,
      referance: fundingData?.referance,
      status: "Advance Request",
      tenure_days: fundingData?.tenure_days,
      transaction_type: fundingData?.transaction_type,

      payment_terms: fundingData?.payment_terms,
      invoice_number: fundingData?.invoice_number,
      supplier_id: fundingData?.supplier_id,
      supplier_name: fundingData?.supplier_name,

      obligor_name: fundingData?.obligor_name,
      obligor_id: fundingData?.obligor_id,
      max_advance: fundingData?.max_advance,
      req_advance_value: fundingData?.req_advance_value,
      invoice_date: fundingData?.invoice_date,

      signer_id: selectedSigner?.[0]?._id,
      signer_name: selectedSignerById,
      signer_email: selectedSigner?.[0]?.email,
    };
    try {
      const res = await patchData({
        endpoint: "funding/UpdateFundingStatus",
        token: cookies.t,
        params: {
          id: fundingData?._id,
        },
        data: dataToSend,
      });
      if (res) {
        toast.success("Funding Request Schedule Updated Successfully", {
          duration: 2000,
          style: confimationStyles,
        });
        toast.success("Funding request form/invoice sent for signature", {
          duration: 2000,
          style: confimationStyles,
        });
        navigate("/dashboard/funding");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const dueDate = moment(fundingData?.createdAt).add(
    parseInt(fundingData?.payment_terms),
    "days",
  );

  const getFileType = (url) => {
    const extension = url?.split(".")?.pop();
    return extension?.toLowerCase();
  };

  const xlsxFileDownload = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
      });
      setExcelData(sheetData);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (modalStateDocs?.data) {
      const fileType = getFileType(modalStateDocs.data?.file);
      if (
        fileType.toLowerCase() === "xls" ||
        fileType.toLowerCase() === "xlsx"
      ) {
        xlsxFileDownload(modalStateDocs.data?.file);
      }
    }
  }, [modalStateDocs]);

  console.log("fundingData", fundingData);
  console.log("modal", modalStateDocs);
  console.log("excelData", excelData);

  return (
    <>
      <Breadcrumb
        labels={["Funding", "Custom Request Schedule"]}
        stepsToRemove={2}
        customState={location?.state?.tab}
      />
      <Heading className="HeadingStyle">Custom Request Schedule</Heading>
      <div>
        <Heading className={style.terms}>Terms</Heading>
        <div className={`row ${style.fundingDetails}`}>
          <div className="col-3">
            <div className={style.label}>Client Entity</div>
            <label className={style.value}>{clientData?.counter_name}</label>
          </div>
          <div className="col-3">
            <div className={style.label}>Client Address</div>
            <label className={style.value}>
              {clientData?.address ? clientData?.address : "N/A"}
            </label>
          </div>
          <div className="col-3">
            <div className={style.label}>Agreement Entity</div>
            <label className={style.value}>
              {clientData?.agreement_counter
                ? clientData?.agreement_counter
                : "N/A"}
            </label>
          </div>
          <div className="col-3">
            <div className={style.label}>Master Agreement Date</div>
            <label className={style.value}>
              {clientData?.agreement_date
                ? moment(clientData?.agreement_date).format("YYYY-MM-DD")
                : "N/A"}
            </label>
          </div>
          {/* <div className="col-3">
            <div className={style.label}>Schedule Template ID</div>
            <label className={style.value}>--</label>
          </div> */}
          <div className="col-3">
            <div className={style.label}>Date</div>
            <label className={style.value}>
              {moment(clientData?.createdAt).format("YYYY-MM-DD")}
            </label>
          </div>
        </div>
      </div>
      <div>
        <Heading className={style.terms}>Underlying Details</Heading>
        <div className={`row ${style.fundingDetails}`}>
          <div className="col-4">
            <div className={style.label}>Obligor Name</div>
            <label className={style.value}>
              {fundingData?.transaction_type === "Supplier"
                ? clientData?.counter_name
                : fundingData?.obligor_name
                ? fundingData?.obligor_name
                : "---"}
            </label>
          </div>
          <div className="col-4">
            <div className={style.label}>Supplier Name</div>
            <label className={style.value}>
              {fundingData?.supplier_name ? fundingData?.supplier_name : "---"}
            </label>
          </div>
          {/* <div className="col-4">
            <div className={style.label}>Reference Number</div>
            <label className={style.value}>
              {fundingData?.referance ? fundingData?.referance : "---"}
            </label>
          </div> */}
        </div>
      </div>
      <div>
        <Heading className={style.terms}>Funding Details</Heading>
        <div className={`row ${style.fundingDetails}`}>
          <div className="col-3">
            <div className={style.label}>Inv Date (or Similar)</div>
            <label className={style.value}>
              {moment(fundingData?.invoice_date).format("YYYY-MM-DD")}
            </label>
          </div>
          <div className="col-3">
            {(fundingData?.transaction_type === "Supplier" ||
              fundingData?.transaction_type === "Supplier Credit" ||
              fundingData?.transaction_type === "Supplier Credit-1") && (
              <>
                <div className={style.label}>Due Date</div>
                <label className={style.value}>
                  {dueDate.format("YYYY-MM-DD")}
                </label>
              </>
            )}
            {(fundingData?.transaction_type === "Revolver" ||
              fundingData?.transaction_type === "Note") && (
              <>
                <div className={style.label}>Due Date</div>
                <label className={style.value}>
                  {moment(fundingData?.due_date)?.format("YYYY-MM-DD")}
                </label>
              </>
            )}
            {(fundingData?.transaction_type === "Obligor" ||
              fundingData?.transaction_type === "Factorial/AR") && (
              <>
                <div className={style.label}>Due Date</div>
                <label className={style.value}>
                  {moment(fundingData?.due_date)?.format("YYYY-MM-DD")}
                </label>
              </>
            )}
          </div>
          {fundingData?.payment_terms && (
            <div className="col-3">
              <div className={style.label}>Payment Terms</div>
              <label className={style.value}>
                {fundingData?.payment_terms}
              </label>
            </div>
          )}
          {fundingData?.invoice_number && (
            <div className="col-3">
              <div className={style.label}>Invoice Number</div>
              <label className={style.value}>
                {fundingData?.invoice_number}
              </label>
            </div>
          )}
          {fundingData?.referance && (
            <div className="col-3">
              <div className={style.label}>Reference</div>
              <label className={style.value}>{fundingData?.referance}</label>
            </div>
          )}
          {fundingData?.tenure_days && (
            <div className="col-3">
              <div className={style.label}>Tennor Days</div>
              <label className={style.value}>{fundingData?.tenure_days}</label>
            </div>
          )}
          {fundingData?.transaction_type && (
            <div className="col-3">
              <div className={style.label}>Transaction Type</div>
              <label className={style.value}>
                {fundingData?.transaction_type}
              </label>
            </div>
          )}
          {fundingData?.max_advance && (
            <div className="col-3">
              <div className={style.label}>Max Advance</div>
              <label className={style.value}>{fundingData?.max_advance}</label>
            </div>
          )}
          {fundingData?.req_advance_value && (
            <div className="col-3">
              <div className={style.label}>Request Advance Value</div>
              <label className={style.value}>
                {fundingData?.req_advance_value}
              </label>
            </div>
          )}
          <div className="col-3">
            <div className={style.label}>Cost Value</div>
            <label className={style.value}>{formatNumberInternational(fundingData?.cost_value)}</label>
          </div>
          <div className="col-3">
            <div className={style.label}>Face Value</div>
            {(fundingData?.transaction_type === "Supplier" ||
              fundingData?.transaction_type === "Supplier Credit" ||
              fundingData?.transaction_type === "Supplier Credit-1" ||
              fundingData?.transaction_type === "Revolver" ||
              fundingData?.transaction_type === "Note" ||
              fundingData?.transaction_type === "Obligor" ||
              fundingData?.transaction_type === "Factorial/AR") && (
              <>
                <label className={style.value}>
                  {formatNumberInternational(fundingData?.face_value)}
                </label>
              </>
            )}
          </div>
          <div className="col-3">
            <div className={style.label}>Currency</div>
            <label className={style.value}>{fundingData?.currency}</label>
          </div>
        </div>
      </div>
      <div>
        <Heading className={style.terms}>Signer Info</Heading>
        <div className="row">
          <Formik
            initialValues={{
              signer_info: "",
            }}
            validationSchema={Yup.object().shape({
              signer_info: Yup.string().required("Signer Info is required"),
            })}
            onSubmit={handleSubmit}
          >
            {({
              setFieldValue,
              touched,
              errors,
              values,
              handleBlur,
              handleSubmit,
              setFieldTouched,
              isSubmitting,
            }) => (
              <>
                <div className="col-4">
                  <MyDropdown
                    placeholder="Signer Info"
                    data={signerData}
                    onChange={async (option) => {
                      const tempData = clientData?.signerDetails?.filter(
                        (item) => item?._id === option,
                      );
                      const res = await getData({
                        endpoint: "signer/getSignerById",
                        token: cookies.t,
                        params: {
                          id: tempData?.[0]?._id,
                        },
                      });
                      console.log("res", res);
                      const name =
                        res?.data?.first_name + " " + res?.data?.last_name;
                      setSelectedSignerId(name);
                      setSelectedSigner(tempData);
                      setFieldValue("signer_info", tempData?.[0]?._id);
                    }}
                    selectedValue={values?.signer_info}
                    touched={touched.signer_info}
                    errors={errors.signer_info}
                    handleBlur={(e) => {
                      setFieldTouched("signer_info", true);
                      handleBlur(e);
                    }}
                    required
                  />
                </div>
                <div className="col-4">
                  <TextInput
                    customholder="Signer Name"
                    type="text"
                    name="signer_name"
                    onBlur={(e) => {
                      // handleBlur(e);
                    }}
                    InputHeight="small"
                    value={selectedSignerById}
                    readOnly
                  />
                </div>
                <div className="col-4">
                  <TextInput
                    customholder="Email"
                    type="text"
                    name="signer_email"
                    onBlur={(e) => {
                      // handleBlur(e);
                    }}
                    InputHeight="small"
                    value={selectedSigner?.[0]?.email}
                    readOnly
                  />
                </div>
                <Heading className={`${style.terms} mt-4`}>
                  Generate and Send Funding Request
                </Heading>
                <div className="col-4 mt-2 mb-2">
                  {/* <CustomButton
                    type="btn-primary"
                    text="Generate Schedule"
                    size="btn-lg"
                    classes={style.buttonStyle}
                    handleClick={() => {
                      handleSubmit(values);
                    }}
                  /> */}
                  <button
                    className="btn-primary btn-lg"
                    type="submit"
                    disabled={isSubmitting}
                    style={{ width: "100%" }}
                    onClick={() => {
                      handleSubmit(values);
                    }}
                  >
                    Continue
                    {isSubmitting && (
                      <span
                        className="spinner-border spinner-border-sm ms-2 text-light"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>

      <CustomDatagrid
        rows={fundingData?.documents || []}
        getRowId={uuidv4}
        columns={[
          {
            field: "file",
            headerName: "Documents Name",
            flex: 1,
            renderCell: (params) => {
              const fileName = params?.row?.file?.split("/").pop();
              return <>{fileName ? removeTimestamp(fileName) : "N/A"}</>;
            },
          },
          {
            field: "imagesType",
            headerName: "File Type",
            flex: 1,
          },
          {
            field: "Action",
            headerName: "Action",
            flex: 0.4,
            renderCell: (params) => {
              console.log("params", params?.row);
              return (
                <>
                  <div
                    style={{
                      cursor: "pointer",
                      color: "#0d6efd",
                      fontWeight: "600",
                    }}
                    onClick={() => {
                      setModalStateDocs(() => ({
                        isModalOpen: true,
                        data: params?.row,
                        modalTitile: params?.row?.imagesType,
                      }));
                    }}
                  >
                    View
                  </div>
                  {/* <div style={{}}>
                    <span
                      type="button"
                      onClick={(event) => handleStatusMenu(event, params.row)}
                    >
                      <HorizontalMenu />
                    </span>
                  </div> */}
                  {/* <CustomMenu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedRow === params.row}
                    onClose={handleClose}
                    items={[
                      {
                        key: "view",
                        icon: <View height="20" width="20" />,
                        label: "View",
                        onClick: () => {
                          setModalStateDocs(() => ({
                            isModalOpen: true,
                            data: params?.row,
                            modalTitile: params?.row?.imagesType,
                          }));
                          setAnchorEl(null);
                        },
                      },
                    ]}
                  /> */}
                </>
              );
            },
          },
        ]}
        height={() => 30}
        hoverColor="ffffff"
        tHeight={"20rem"}
      />
      <Modal
        isActive={modalStateDocs.isModalOpen}
        title={modalStateDocs.modalTitile}
        onClose={() => {
          setModalStateDocs(() => ({
            isModalOpen: false,
            data: "",
          }));
        }}
        width="700px"
        bodyStyles={{
          overflowX: "auto",
          padding: "20px",
        }}
      >
        {getFileType(modalStateDocs.data.file) === "docx" && (
          <FileViewer
            fileType="docx"
            filePath={modalStateDocs.data.file}
            pluginRenderers={DocViewerRenderers}
          />
        )}
        {(getFileType(modalStateDocs.data.file) === "png" ||
          getFileType(modalStateDocs.data.file) === "jpeg") && (
          <img src={modalStateDocs?.data?.file} alt="images" />
        )}
        {getFileType(modalStateDocs.data.file) === "pdf" && (
          <FileViewer
            fileType="pdf"
            filePath={modalStateDocs.data.file}
            pluginRenderers={DocViewerRenderers}
          />
        )}
        {excelData && (
          <>
            <div className={style.excelBoadyWrap}>
              <table border="1">
                <thead>
                  <tr>
                    {excelData?.[0].map((header, i) => (
                      <th className={style.headerStyleCell} key={i}>
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {excelData?.slice(1)?.map((row, i) => (
                    <tr key={i}>
                      {row?.map((cell, j) => (
                        <td className={style.columnCellStyle} key={j}>
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
export default ViewDraftFunding;
