import React, { useState, useRef, useEffect } from 'react';
import './styles.scss'

const ViewMore = ({ data, lineClamp = 5 }) => {
  const [isViewMore, setIsViewMore] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      const { scrollHeight, clientHeight } = textRef.current;
      // Check if the content is clamped
      setIsClamped(scrollHeight > clientHeight);
    }
  }, [data, lineClamp]);

  return (
    <>
      <div
        ref={textRef}
        className={isViewMore ? '' : 'clamp-text'}
        style={{
          WebkitLineClamp: !isViewMore ? lineClamp : null,
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        }}
      >
        {data}
      </div>

      {isClamped && (
        <div className="view-more" onClick={() => setIsViewMore((prev) => !prev)}>
          {isViewMore ? 'View Less' : 'View More'}
        </div>
      )}
    </>
  );
};

export default ViewMore;
