import { useEffect, useState } from "react";
import CounterPartyBasicInfo from "../counterpartyBasicInfo";
import style from "./index.module.scss";
import { getData, postData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import moment from "moment";
import CustomButton from "../../../../../atoms/CustomButton";

const MonitoringSend = () => {
  const [cookies] = useCookies(["t"]);
  const { id } = useParams();
  const [clientData, setClientData] = useState({});
  const [reportingData, setReportingData] = useState();

  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getReportingRequirementById = async () => {
    try {
      const res = await getData({
        endpoint:
          "reporting-requirements/getPeportingRequiremetsDetailsByClientId",
        params: {
          client_id: id,
        },
        token: cookies.t,
      });
      setReportingData(res?.data?.reporting_requirement_details);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (id) {
      getCounterDataById();
      getReportingRequirementById();
    }
  }, [id]);

  const handleSendInformation = async ({ type }) => {
    try {
      const res = await getData({
        endpoint: "couterParty/sendNotification",
        token: cookies.t,
        params: {
          client_id: id,
          notificationType: type,
        },
      });
      console.log("res", res);
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <div className={style.duesWrap}>
        <div className={style.clientBasicInfo}>
          <CounterPartyBasicInfo clientData={clientData} />
        </div>
        <div className={style.tableInfoWrap}>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Client Reporting Information</th>
                <th scope="col"> </th>
              </tr>
              <tbody>
                <tr>
                  <td
                    style={{
                      border: "1px solid #DEE3E7",
                      padding: "8px",
                    }}
                  >
                    Client Name:
                  </td>
                  <td
                    style={{
                      border: "1px solid #DEE3E7",
                      padding: "8px",
                    }}
                  >
                    {clientData?.counter_type === "Client"
                      ? clientData?.counter_name
                      : clientData?.client_name}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #DEE3E7",
                      padding: "8px",
                    }}
                  >
                    Report Request Email(s):
                  </td>
                  <td
                    style={{
                      border: "1px solid #DEE3E7",
                      padding: "8px",
                    }}
                  >
                    {/* {clientData?.counter_type === "Client"
                      ? clientData?.counter_name
                      : clientData?.client_name} */}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #DEE3E7",
                      padding: "8px",
                    }}
                  >
                    Last Recived As Of Report Date:
                  </td>
                  <td
                    style={{
                      border: "1px solid #DEE3E7",
                      padding: "8px",
                    }}
                  >
                    {/* {clientData?.counter_type === "Client"
                      ? clientData?.counter_name
                      : clientData?.client_name} */}
                  </td>
                </tr>
              </tbody>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  marginTop: "10px",
                }}
              >
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      Requirement
                    </th>
                    <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      Requirement (Detail)
                    </th>
                    <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      Frequency
                    </th>
                    <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      Shift
                    </th>
                    <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      Reporting Days
                    </th>
                    <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      Next Report As of Date
                    </th>
                    <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                      Next Due Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reportingData?.length > 0 ? (
                    reportingData?.map((item, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            border: "1px solid #DEE3E7",
                            padding: "8px",
                            minWidth: "auto",
                          }}
                        >
                          {item.name}
                        </td>
                        <td
                          style={{
                            border: "1px solid #DEE3E7",
                            padding: "8px",
                            minWidth: "auto",
                          }}
                        >
                          {item.long_description}
                        </td>
                        <td
                          style={{
                            border: "1px solid #DEE3E7",
                            padding: "8px",
                            minWidth: "auto",
                          }}
                        >
                          {item.frequency}
                        </td>
                        <td
                          style={{
                            border: "1px solid #DEE3E7",
                            padding: "8px",
                            minWidth: "auto",
                          }}
                        >
                          {item.monthly_shift}
                        </td>
                        <td
                          style={{
                            border: "1px solid #DEE3E7",
                            padding: "8px",
                            minWidth: "auto",
                          }}
                        >
                          {item.reporting_days}
                        </td>
                        <td
                          style={{
                            border: "1px solid #DEE3E7",
                            padding: "8px",
                            minWidth: "auto",
                          }}
                        >
                          {moment(item.next_reporting_date).format(
                            "Do MMM, YYYY",
                          )}
                        </td>
                        <td
                          style={{
                            border: "1px solid #DEE3E7",
                            padding: "8px",
                            minWidth: "auto",
                          }}
                        >
                          {moment(item.next_due_date).format("Do MMM, YYYY")}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={7} // Adjust to the total number of columns
                        style={{
                          border: "1px solid #DEE3E7",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </thead>
          </table>
          <div className={style.ButtonWrap}>
            <CustomButton
              classes="mt-2 d-flex"
              type="btn-primary"
              text="Send Request ('Information Notice')"
              handleClick={() => {
                handleSendInformation({ type: "Information Notice" });
              }}
            />
            <CustomButton
              classes="mt-2 d-flex"
              type="btn-primary"
              text="Send Request ('Coming Due Notice')"
              handleClick={() => {
                handleSendInformation({ type: "Coming Due Notice" });
              }}
            />
            <CustomButton
              classes="mt-2 d-flex"
              type="btn-primary"
              text="Send Request ('Due Notice')"
              handleClick={() => {
                handleSendInformation({ type: "Due Notice" });
              }}
            />
            <CustomButton
              classes="mt-2 d-flex"
              type="btn-primary"
              text="Send Request ('1st Notice Pas Due')"
              handleClick={() => {
                handleSendInformation({ type: "1st Notice Pas Due" });
              }}
            />
            <CustomButton
              classes="mt-2 d-flex"
              type="btn-primary"
              text="Send Request ('2st Notice Pas Due')"
              handleClick={() => {
                handleSendInformation({ type: "2st Notice Pas Due" });
              }}
            />
            <CustomButton
              classes="mt-2 d-flex"
              type="btn-primary"
              text="Send Request ('Final Notice')"
              handleClick={() => {
                handleSendInformation({ type: "Final Notice" });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default MonitoringSend;
