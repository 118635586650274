import { Formik } from "formik";
import InputLayout from "../../../../atoms/InputLayout";
import TextInput from "../../../../atoms/TextInput";
import {
  CurrencyData,
  fundingPaymentTerms,
  ImagestypeData,
} from "../../../../../utils/staticData";
import CustomButton from "../../../../atoms/CustomButton";
import { useNavigate } from "react-router-dom";
import style from "./index.module.scss";
import { Heading } from "../../../../atoms/Heading";
import ImageSelector from "../../../../atoms/ImageSelector/ImageSelector";
import { postFormData } from "../../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import * as Yup from "yup";
import MyDropdown from "../../../../atoms/MyDropdown";
import { useState } from "react";
import Close from "../../../../icons/Close";
import {
  Obj2FormData,
  Obj2FormImage,
} from "../../../../../helpers/capatalizeLetters";
import Modal from "../../../../atoms/modals/Modal";
import moment from "moment/moment";
import UploadSupportingDocument from "./UploadSupportingDocument";
import { useEffect } from "react";
import { getData } from "../../../../../services";
import { currencySymbols } from "../../../../../utils/staticData";
import { convertAndAddCurrencies } from "../../dashboard/helper/currency";
import { warningStyles } from "../../../../../assets/styles/components/toast";
const Supplier = ({
  supplierObligorData,
  clientDetails,
  selectedTransactionType,
  currencyConversion
}) => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState([]);
  const [modalStateImage, setModalStateImage] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false,
    data: "",
  });

  const [modalImageType, setModalImagesType] = useState({
    isModalOpen: false,
    modalTitle: "",
  });
  const [creditData, setCreditData] = useState([])


  const [imagesName, setImagesName] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const [fileType, setFileType] = useState();

  const handleRemoveImage = (imageToRemove) => {
    setSelectedImage((prevImages) =>
      prevImages.filter((image) => image?.file !== imageToRemove),
    );
  };
  const handleMultipleImages = (file, imagesType) => {
    setSelectedImage((prev) => [
      ...prev,
      { file: file, imagesType: imagesType },
    ]);
  };
  const handleCloseModal = () => {
    setModalStateImage(() => ({
      isModalOpen: false,
      data: "",
    }));
  };
  const handleClosrModalImagesType = () => {
    setModalImagesType(() => ({
      isModalOpen: false,
      modalTitle: "",
    }));
  };

  const initialValues = {
    supplier_id: "",
    supplier_name: "",
    funding_term: "",
    currency: "",
    supplier_invoice_face_value: "",
    supplier_invoce_number: "",
    supplier_supporting_document: "",
  };
  const validationSchema = Yup.object().shape({
    supplier_id: Yup.string().required("Supplier name is required"),
    funding_term: Yup.number().required("Funding payment terms is required").max(180, "Funding payment terms should be less than 180 days"),
    currency: Yup.string().required("Currency is required"),
    supplier_invoice_face_value: Yup.number().required(
      "Supplier invoice face value is required",
    ),
    supplier_invoce_number: Yup.string().required(
      "Supplier invoice number is required",
    ),
    supplier_supporting_document: Yup.array().test(
      "images-length",
      "Supporting documents is required",
      function (value) {
        // Custom test to check if images length is greater than 0
        return selectedImage.length > 0;
      },
    ),
  });

  const handleSubmit = async (values) => {
    const tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
    const dueDate = moment(tomorrow)
      .add(parseInt(values.funding_term), "days")
      .utc()
      .startOf("day");
    const dataToSend = {
      currency: values.currency,
      payment_terms: values.funding_term,
      invoice_number: values.supplier_invoce_number,
      cost_value: values.supplier_invoice_face_value,
      client_id: clientDetails.clientId,
      client_name: clientDetails?.clientName,
      supplier_id: values.supplier_id,
      supplier_name: values.supplier_name,
      status: "Draft",
      transaction_type: selectedTransactionType.selectedType,
      documents: selectedImage,
      due_date: dueDate,
      funded_date: moment().add(1, "days").format("YYYY-MM-DD"),
    };
    try {
      const res = await postFormData({
        endpoint: "funding/createFunding",
        token: cookies.t,
        data: dataToSend,
      });
      if (res) {
        toast.success("Funding Created Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
      navigate("/dashboard/funding");
    } catch (err) {
      console.log("err", err);
    }
  };

  // useEffect(() => {
    const fetchClientCreditData = async (value) => {
        const res = await getData({
          endpoint: "couterParty/clientCreditLeft",
          token: cookies.t,
          params: {
            client_id: value,
            trans_type: selectedTransactionType.selectedType,
          }
        });
        // Handle the response as needed
        setCreditData(res?.data)
    };
  
  //   fetchData();
  // }, [selectedTransactionType]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        key={1234}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          touched,
          errors,
          values,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          isSubmitting,
          resetForm,
        }) => (
          <>
            <Heading className="addHeaderStyle mt-4">Funding Details</Heading>
            <div className="row">
              <div className="col-3 mt-2">
                <MyDropdown
                  data={supplierObligorData}
                  placeholder="Supplier Name"
                  name="supplier_name"
                  onChange={(option) => {
                    setFieldValue("supplier_id", option?.value);
                    setFieldValue("supplier_name", option.label);
                    fetchClientCreditData(option?.value)
                  }}
                  format="all"
                  touched={touched.supplier_id}
                  errors={errors.supplier_id}
                  selectedValue={values?.supplier_id}
                  handleBlur={(e) => {
                    setFieldTouched("supplier_id", true);
                    handleBlur(e);
                  }}
                  required
                />
              </div>
              <div className="col-3 mt-2">
                {/* <MyDropdown
                  name="funding_term"
                  data={fundingPaymentTerms}
                  placeholder="Funding Payment Terms"
                  selectedValue={values?.funding_term}
                  onChange={(option) => {
                    setFieldValue("funding_term", option);
                  }}
                  touched={touched.funding_term}
                  errors={errors.funding_term}
                  handleBlur={(e) => {
                    setFieldTouched("funding_term", true);
                    handleBlur(e);
                  }}
                  required
                /> */}
                <InputLayout>
                  <TextInput
                    type="text"
                    name="funding_term"
                    customholder="Funding Payment Terms"
                    onBlur={(e) => {
                      // handleBlur(e);
                    }}
                    InputHeight="small"
                    required
                  />
                </InputLayout>
              </div>
              <div className="col-3 mt-2">
                <MyDropdown
                  name="Currency"
                  data={CurrencyData}
                  placeholder="Currency"
                  onChange={(option) => {
                    setFieldValue("currency", option);
                  }}
                  touched={touched.currency}
                  errors={errors.currency}
                  selectedValue={values?.currency}
                  handleBlur={(e) => {
                    setFieldTouched("currency", true);
                    handleBlur(e);
                  }}
                  required
                />
              </div>

              {console.log('va;',values)}
              <div className="col-3 mt-2">
                <InputLayout>
                  <TextInput
                    type="text"
                    name="supplier_invoice_face_value"
                    customholder="Supplier Invoice Face Value"
                    onBlur={(e) => {
                      if (
                        e.target.value >
                        creditData?.creditLimit - convertAndAddCurrencies(creditData?.amountFunded, currencyConversion,'number')
                      ) {
                        toast(
                          "Invoice Face Value is greater than the credit limit, please update the Credit limit for this Counterparty.",
                          { style: warningStyles, duration: 5000 },
                        );
                      }
                    }} 
                    InputHeight="small"
                    required
                    info={ values.supplier_id ? `Credit Left : ${currencySymbols["USD"]} ${(creditData?.creditLimit - convertAndAddCurrencies(creditData?.amountFunded, currencyConversion,'number')).toFixed(2)}` : ""   }
                  />
                </InputLayout>
              </div>
              <div className="col-6 mt-4">
                <InputLayout>
                  <TextInput
                    type="text"
                    name="supplier_invoce_number"
                    customholder="Supplier Invoice Number(s)/ Reference(s)"
                    onBlur={(e) => {
                      // handleBlur(e);
                    }}
                    InputHeight="small"
                    required
                  />
                </InputLayout>
              </div>
              <div className="col-3 mt-4">
                <UploadSupportingDocument handleMultipleImages={handleMultipleImages} ImagestypeData={ImagestypeData} cookie={cookies} clientDetails={clientDetails}/>
                {/* <ImageSelector
                  placeholder="Upload Supporting Documents"
                  handleFile={async (file) => {
                    const formData = new FormData();
                    formData.append("files", file, file?.name);
                    const dataToSend = {
                      files: formData,
                      client_id: clientDetails?.clientId,
                      client_name: clientDetails?.clientName,
                    };
                    setImageLoading(true);
                    const res = await postFormData({
                      endpoint: "funding/uploadFile",
                      token: cookies.t,
                      // data: Obj2FormImage(formData),
                      data: Obj2FormData(dataToSend, formData),
                    });
                    setImageLoading(false);
                    setImagesName(res?.data?.data?.[0]);
                    setModalImagesType(() => ({
                      isModalOpen: true,
                      modalTitle: "Select File Type:",
                    }));
                  }}
                  required
                  multiple
                  touched={touched.supplier_supporting_document}
                  errors={errors.supplier_supporting_document}
                />
                {imageLoading && (
                  <span
                    className="spinner-border spinner-border-lg ms-5 text-primary"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )} */}
              </div>
              {selectedImage?.map((item) => {
                return (
                  <div className={`col-3 mt-4`}>
                    <div className={style.imagesNameStyle}>
                      <span
                        className={style.imagesName}
                        onClick={() => {
                          if (
                            item?.type === "application/pdf" ||
                            item?.type === "image/jpeg"
                          ) {
                            setModalStateImage(() => ({
                              data: item?.file,
                              isModalOpen: true,
                            }));
                          } else {
                            //docs and excel not supported on local
                            setModalStateImage(() => ({
                              data: item?.file,
                              isModalOpen: true,
                            }));
                          }
                        }}
                      >
                        {
                          item?.file?.split("/")?.[
                            item?.file?.split("/")?.length - 1
                          ]
                        }
                      </span>
                      <div
                        className={style.closeIcon}
                        onClick={() => {
                          handleRemoveImage(item?.file);
                        }}
                      >
                        <Close />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={style.buttonWrap}>
              <div>
                <CustomButton
                  text="Back"
                  size="btn-lg"
                  type="btn-outline-dark"
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              <div className={style.resetAdd}>
                <CustomButton
                  text="Reset"
                  type="btn-outline-primary"
                  size="btn-lg"
                  handleClick={() => {
                    setSelectedImage([]);
                    resetForm();
                  }}
                />
                {/* <CustomButton
                  text={"Add"}
                  type="btn-primary"
                  size="btn-lg"
                  disabled={isSubmitting}
                  handleClick={() => {
                    handleSubmit(values);
                  }}
                /> */}
                <button
                  className="btn-primary btn-lg"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ width: "100%" }}
                  onClick={() => {
                    handleSubmit(values);
                  }}
                >
                  {" "}
                  Add
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </Formik>
      <Modal
        isActive={modalStateImage.isModalOpen}
        onClose={handleCloseModal}
        width="700px"
        bodyStyles={{ height: "700px" }}
      >
        <iframe
          width="100%"
          height="100%"
          src={modalStateImage.data}
          title="hello"
          // sandbox="allow-scripts allow-same-origin"
        />
      </Modal>
      <Modal
        isActive={modalImageType.isModalOpen}
        title={modalImageType.modalTitle}
        onClose={handleClosrModalImagesType}
        width="400px"
        bodyStyles={{ height: "400px" }}
      >
        <div className={style.modalStyleButton}>
          <MyDropdown
            data={ImagestypeData}
            onChange={(option) => {
              setFileType(option);
              // handleMultipleImages(imagesName, option);
              // setModalImagesType(() => ({
              //   isModalOpen: false,
              // }));
            }}
            placeholder="Select File Type"
          />
          <div>
            <CustomButton
              text="Confirm Selection"
              type="btn-primary"
              handleClick={() => {
                handleMultipleImages(imagesName, fileType);
                setModalImagesType(() => ({
                  isModalOpen: false,
                }));
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default Supplier;
