import React, { useEffect, useMemo, useState } from "react";
import Tabstrip from "../../../../atoms/tabstrip";
import { Box } from "@mui/material";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import { Heading } from "../../../../atoms/Heading";
import Basic from "../add/Basic";
import { useLocation, useParams } from "react-router-dom";
import { isDraft } from "@reduxjs/toolkit";
import ViewCounterparty from ".";
import CounterPartyDue from "./Dues";
import MonitoringSend from "./MonitoringSend";
import MonitoringAdd from "./MonitoringAdd";
import MonitoringCovenants from "./MonitoringCovenants";
import MonitoringReview from "./MonitoringReview";
import ExposureGraph from "./ExposureGraph";
import { getData } from "../../../../../services";
import { useCookies } from "react-cookie";

const ViewCounterTabs = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Related Parties Info");
  const { id, isDraft } = useParams();
  const [cookies] = useCookies(["t"]);
  const [counterData, setCounterData] = useState([]);
  console.log("id", id);

  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setCounterData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (id) {
      getCounterDataById();
    }
  }, [id]);

  console.log("counterData", counterData);

  useEffect(() => {
    if (location?.state) {
      setActiveTab(location?.state?.edit);
    }
  }, [location?.state]);

  const data = useMemo(
    () => [
      {
        id: 1,
        label: "Related Parties Info",
        view: <ViewCounterparty type="Related Parties Info" />,
      },
      {
        id: 2,
        label: "Internal Info",
        view: <ViewCounterparty type="Internal Info" />,
      },
      {
        id: 3,
        label: "Due",
        view: (
          <>
            <CounterPartyDue />
          </>
        ),
      },
      {
        id: 4,
        label: "Liquidity: Cumulative (WIP)",
        view: <></>,
      },
      {
        id: 5,
        label: "Exposure: Obligor",
        view: <ExposureGraph title="Exposure: Obligor" type="Obligor" />,
      },
      {
        id: 6,
        label: "Exposure: Supplier",
        view: <ExposureGraph title="Exposure: Supplier" type="Supplier" />,
      },
      ...(counterData?.counter_type === "Client"
        ? [
            {
              id: 7,
              label: "Monitoring: Send",
              view: <MonitoringSend />,
            },
            {
              id: 8,
              label: "Monitoring: Add",
              view: <MonitoringAdd setActiveTab={setActiveTab} />,
            },
            {
              id: 9,
              label: "Monitoring: Review",
              view: <MonitoringReview />,
            },
            {
              id: 10,
              label: "Monitoring: Covenants",
              view: <MonitoringCovenants />,
            },
          ]
        : []),
    ],
    [counterData],
  );

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };
  const handleAllowId = () => {
    if (location?.state?.edit === "Client") {
      return 1;
    } else if (location?.state?.edit === "Supplier") {
      return 2;
    } else if (localStorage?.state?.edit === "Obligor") {
      return 3;
    } else {
      return 4;
    }
  };

  return (
    <>
      <div className="fixed-part">
        <Breadcrumb
          labels={["Counterparties", "View Counterparties"]}
          excludeLastPath
        />
        <Heading className="addHeaderStyle">View Counterparty </Heading>

        <Tabstrip
          activeTab={activeTab}
          onTabChange={onTabChange}
          data={data}
          allowedTabId={location?.state?.edit ? handleAllowId : ""}
          areTabsDisabled={location?.state?.edit ? true : false}
        />
      </div>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ p: 2 }}>{_getComponent(activeTab)}</Box>
      </Box>
    </>
  );
};

export default ViewCounterTabs;
