import moment from "moment";
import style from "./index.module.scss";
import ViewMore from "../../../../../atoms/ViewMore";
import { formatNumberInternational } from "../../../../../../helpers/capatalizeLetters";

const CounterPartyBasicInfo = ({ clientData }) => {
  console.log("clientData", clientData);
  return (
    <>
      <div className={style.fundedViewleftWrap}>
        <table class="table">
          <thead>
            <tr>
              <th
                style={{
                  borderLeft: "1px solid #DEE3E7",
                  borderTop: "1px solid #DEE3E7",
                  borderRight: "1px solid #DEE3E7",
                  padding: "8px",
                }}
                scope="col-2"
                colSpan="2"
              >
                Counterparty Information
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  width: "35%",
                }}
                className={style.label}
              >
                Name:
              </td>
              <td className={style.value}>
                {clientData?.counter_type === "Client"
                  ? clientData?.counter_name
                  : clientData?.client_name}
              </td>
            </tr>
            <tr>
              <td className={style.label}>Address:</td>
              <td className={style.value}>
                {clientData?.address ? clientData?.address : "N/A"}
              </td>
            </tr>
            <tr>
              <td className={style.label}>TaxID:</td>
              <td className={style.value}>
                {clientData?.tax_id ? clientData?.tax_id : "N/A"}
              </td>
            </tr>
            <tr>
              <td className={style.label}>Type:</td>
              <td
                className={style.value}
              >
                {clientData?.counter_type}
              </td>
            </tr>
            <tr>
              <td
                className={style.label}
              >
                Verified:
              </td>
              <td
                className={style.value}
              >
                {clientData?.verified ? clientData?.verified : "N/A"}
              </td>
            </tr>
            <tr>
              <td
                className={style.label}
              >
                Notes:
              </td>
              <td
                className={style.value}
              >
                {clientData?.notes ? (
                  <ViewMore data={clientData?.notes} />
                ) : (
                  "N/A"
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table">
          <thead>
            <tr>
              <th scope="col" colSpan={2}>
                Credit Information
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className={style.label}
                style={{
                  width: "35%",
                }}
              >
                Counterparty Type:
              </td>
              <td
                className={style.value}
              >
                {clientData?.counter_type}
              </td>
            </tr>
            <tr>
              <td
                className={style.label}
              >
                Internal Credit Limit:
              </td>
              <td
                className={style.value}
              >
                {clientData?.credit_limit ? formatNumberInternational(clientData?.credit_limit) : "N/A"}
              </td>
            </tr>
            <tr>
              <td
                className={style.label}
              >
                Credit Used:
              </td>
              <td
                className={style.value}
              >
                {clientData?.credit_limit
                  ? formatNumberInternational(parseFloat(clientData?.credit_limit))
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <td
                className={style.label}
              >
                Credit Ramaining:
              </td>
              <td
                className={style.value}
              >
                {clientData?.credit_limit
                  ? formatNumberInternational(parseFloat(clientData?.credit_limit))
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <td
                className={style.label}
              >
                Sector:
              </td>
              <td
                className={style.value}
              >
                {clientData?.gics_sector ? clientData?.gics_sector : "N/A"}
              </td>
            </tr>
            <tr>
              <td
                className={style.label}
              >
                Industry:
              </td>
              <td
                className={style.value}
              >
                {clientData?.gics_industry ? clientData?.gics_industry : "N/A"}
              </td>
            </tr>
            <tr>
              <td
                className={style.label}
              >
                Geography:
              </td>
              <td
                className={style.value}
              >
                {clientData?.geography ? clientData?.geography : "N/A"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default CounterPartyBasicInfo;
