import { useEffect, useState } from "react";
import CustomButton from "../../../../../atoms/CustomButton";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";
import style from "./index.module.scss";
import moment from "moment/moment";
import { getData, patchData, postData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import { breakStringInMiddle, formatNumberInternational } from "../../../../../../helpers/capatalizeLetters";
import { CustomInput, CustomDropDown } from "../../../../../atoms/CustomInput";
import { useNavigate, useParams } from "react-router-dom";
import { Heading } from "../../../../../atoms/Heading";
import Breadcrumb from "../../../../../molecules/Breadcrumb";
import PrimitiveDropdown from "../../../../../atoms/PrimitiveDropdown";

const ReviewView = () => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [fundingDueData, setFundingDueData] = useState([]);
  const [fundingDetails, setFundingDetails] = useState({
    client_id: "",
    supplier_id: "",
    trans_type: "",
  });
  const [manualAdv, setManualAdv] = useState(false);
  const [autoApplyInterest, setAutoApplyInterest] = useState(false);
  const [autoApplyCost, setAutoApplyCost] = useState(false);
  const [autoApplyFull, setAutoApplyFull] = useState(false);
  const [reservedAmount, setReservedAmount] = useState();
  const [paymentInfoData, setPaymentInfoData] = useState();
  const [assignmentType, setAssigmentType] = useState("Assign Singular");
  const createDocRecords = (num) => {
    return Array.from({ length: num }, () => ({
      paymentApp: "",
      postAppStatus: "",
      paymentType: "",
      currency: "",
    }));
  };

  const GetPaymentDataById = async () => {
    try {
      const res = await getData({
        endpoint: "/payment/getPaymentById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setPaymentInfoData(res?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (id) {
      GetPaymentDataById();
    }
  }, [id]);

  const [manualAdvData, setManualAdvData] = useState(createDocRecords(0));

  const getFundingDues = async () => {
    try {
      const res = await getData({
        endpoint: "funding/geFundingClientIdAndSupplierId",
        params: {
          client_id: fundingDetails?.client_id,
          supplier_id: fundingDetails?.supplier_id,
          trans_type: fundingDetails?.trans_type,
        },
        token: cookies.t,
      });
      setFundingDueData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  console.log("fundingDetails", fundingDetails);
  useEffect(() => {
    if (fundingDetails?.client_id) {
      getFundingDues();
    }
  }, [fundingDetails, id]);

  console.log("fundingDueData", fundingDueData);
  console.log("mannualAdvData", manualAdvData);
  console.log("reservedAmount", reservedAmount);
  console.log("paymentInfoData", paymentInfoData);

  useEffect(() => {
    if (paymentInfoData?.client_id) {
      if (
        paymentInfoData?.obligor_id &&
        paymentInfoData?.transaction_type === "Obligor"
      ) {
        setFundingDetails(() => ({
          client_id: paymentInfoData?.client_id,
          supplier_id: paymentInfoData?.obligor_id,
          trans_type: paymentInfoData?.transaction_type,
        }));
      } else if (
        paymentInfoData?.obligor_id &&
        (paymentInfoData?.transaction_type === "Supplier" ||
          paymentInfoData?.transaction_type === "Supplier Credit")
      ) {
        setFundingDetails(() => ({
          client_id: paymentInfoData?.client_id,
          supplier_id: paymentInfoData?.obligor_id,
          trans_type: paymentInfoData?.transaction_type,
        }));
      } else if (paymentInfoData?.transaction_type === "Supplier Credit-1") {
        setFundingDetails(() => ({
          client_id: paymentInfoData?.client_id,
          supplier_id: paymentInfoData?.obligor_id,
          trans_type: "Supplier",
        }));
      } else if (paymentInfoData?.transaction_type === "Client") {
        setFundingDetails(() => ({
          client_id: paymentInfoData?.client_id,
          supplier_id: paymentInfoData?.obligor_id,
          trans_type: paymentInfoData?.transaction_type,
        }));
      }
    }
  }, [paymentInfoData, id]);

  const handleConfirmAndApply = async () => {
    console.log("manualAdvData", manualAdvData);
    if (manualAdv) {
      try {
        const res = await patchData({
          endpoint: "funding/updatePaymentHistory",
          data: manualAdvData,
          token: cookies.t,
        });
        if (res) {
          if (parseInt(reservedAmount) === parseFloat(0.0)) {
            try {
              const res = await patchData({
                endpoint: "payment/paymentUpdate",
                token: cookies.t,
                params: { id: paymentInfoData?._id },
                data: {
                  ...paymentInfoData,
                  remainingAmount: reservedAmount,
                  status: "Assigned",
                },
              });
              if (res) {
                navigate("/dashboard/payments");
                // setAssignModalState(() => ({
                //   isModalOpen: false,
                //   modalId: "",
                //   modalTitle: "",
                //   data: "",
                // }));
                // getPaymentStatus();
              }
            } catch (err) {
              console.log("err", err);
            }
          } else if (parseInt(reservedAmount) > parseFloat(0.0)) {
            try {
              const res = await patchData({
                endpoint: "payment/paymentUpdate",
                token: cookies.t,
                params: { id: paymentInfoData?._id },
                data: {
                  ...paymentInfoData,
                  remainingAmount: reservedAmount,
                  status: "Reserves",
                },
              });
              if (res) {
                navigate("/dashboard/payments");
                // setAssignModalState(() => ({
                //   isModalOpen: false,
                //   modalId: "",
                //   modalTitle: "",
                //   data: "",
                // }));
                // getPaymentStatus();
              }
            } catch (err) {
              console.log("err", err);
            }
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    } else if (autoApplyInterest) {
      try {
        const res = await patchData({
          endpoint: "funding/updatePaymentHistory",
          data: manualAdvData,
          token: cookies.t,
        });
        if (res) {
          if (parseInt(reservedAmount) === parseFloat(0.0)) {
            try {
              const res = await patchData({
                endpoint: "payment/paymentUpdate",
                token: cookies.t,
                params: { id: paymentInfoData?._id },
                data: {
                  ...paymentInfoData,
                  remainingAmount: reservedAmount,
                  status: "Assigned",
                },
              });
              if (res) {
                navigate("/dashboard/payments");
                // setAssignModalState(() => ({
                //   isModalOpen: false,
                //   modalId: "",
                //   modalTitle: "",
                //   data: "",
                // }));
                // getPaymentStatus();
              }
            } catch (err) {
              console.log("err", err);
            }
          } else if (parseInt(reservedAmount) > parseFloat(0.0)) {
            try {
              const res = await patchData({
                endpoint: "payment/paymentUpdate",
                token: cookies.t,
                params: { id: paymentInfoData?._id },
                data: {
                  ...paymentInfoData,
                  remainingAmount: reservedAmount,
                  status: "Reserves",
                },
              });
              if (res) {
                navigate("/dashboard/payments");
                // setAssignModalState(() => ({
                //   isModalOpen: false,
                //   modalId: "",
                //   modalTitle: "",
                //   data: "",
                // }));
                // getPaymentStatus();
              }
            } catch (err) {
              console.log("err", err);
            }
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    } else if (autoApplyFull) {
      try {
        const res = await patchData({
          endpoint: "funding/updatePaymentHistory",
          data: manualAdvData,
          token: cookies.t,
        });
        if (res) {
          if (parseFloat(0.0) == parseInt(reservedAmount)) {
            try {
              const res = await patchData({
                endpoint: "payment/paymentUpdate",
                token: cookies.t,
                params: { id: paymentInfoData?._id },
                data: {
                  ...paymentInfoData,
                  remainingAmount: reservedAmount,
                  status: "Assigned",
                },
              });
              if (res) {
                navigate("/dashboard/payments");
                // setAssignModalState(() => ({
                //   isModalOpen: false,
                //   modalId: "",
                //   modalTitle: "",
                //   data: "",
                // }));
                // getPaymentStatus();
              }
            } catch (err) {
              console.log("err", err);
            }
          } else if (parseInt(reservedAmount) > parseFloat(0.0)) {
            try {
              const res = await patchData({
                endpoint: "payment/paymentUpdate",
                token: cookies.t,
                params: { id: paymentInfoData?._id },
                data: {
                  ...paymentInfoData,
                  amount:
                    parseFloat(paymentInfoData?.amount) -
                    parseFloat(reservedAmount),
                  remainingAmount: reservedAmount,
                  status: "Assigned",
                },
              });
              if (res) {
                const createRes = await postData({
                  endpoint: "payment/create",
                  token: cookies.t,
                  data: {
                    amount: reservedAmount,
                    status: "Reserves",
                    client_id: paymentInfoData?.client_id,
                    client_name: paymentInfoData?.client_name,
                    obligor_id: paymentInfoData?.obligor_id,
                    obligor_name: paymentInfoData?.obligor_name,
                    currency: paymentInfoData?.currency,
                    date: paymentInfoData?.date,
                    transaction_type: paymentInfoData?.transaction_type,
                  },
                });
                if (createRes) {
                  navigate("/dashboard/payments");
                  // setAssignModalState(() => ({
                  //   isModalOpen: false,
                  //   modalId: "",
                  //   modalTitle: "",
                  //   data: "",
                  // }));
                  // getPaymentStatus();
                }
              }
            } catch (err) {
              console.log("err", err);
            }
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  // const updateFundingData = (
  //   fundingDueData,
  //   paymentInfoData,
  //   isFull = false,
  //   isInterest = false,
  // ) => {
  //   let remainingAmount = parseFloat(paymentInfoData?.amount) || 0;

  //   const updatedData = fundingDueData.map((item) => {
  //     const totalValue = isFull
  //       ? parseFloat(item?.cost_value) + parseFloat(item?.face_value)
  //       : isInterest
  //       ? parseFloat(item?.face_value)
  //       : parseFloat(item?.cost_value);

  //     if (remainingAmount >= totalValue) {
  //       remainingAmount -= totalValue;
  //       return {
  //         ...item,
  //         paymentApp: totalValue.toFixed(2),
  //         postAppStatus: isFull ? "Close" : "open",
  //         paymentType: isInterest
  //           ? "Interest"
  //           : isFull
  //           ? "PIK-Interest"
  //           : "Principle",
  //         paymentId: paymentInfoData?._id,
  //       };
  //     } else if (remainingAmount > 0) {
  //       const currentAmount = remainingAmount;
  //       remainingAmount = 0;
  //       return {
  //         ...item,
  //         paymentApp: currentAmount.toFixed(2),
  //         postAppStatus: "open",
  //         paymentType: isInterest
  //           ? "Interest"
  //           : isFull
  //           ? "PIK-Interest"
  //           : "Principle",
  //         paymentId: paymentInfoData?._id,
  //       };
  //     } else {
  //       return {
  //         ...item,
  //         paymentApp: 0,
  //         postAppStatus: "Open",
  //         paymentType: isInterest
  //           ? "Interest"
  //           : isFull
  //           ? "PIK-Interest"
  //           : "Principle",
  //         paymentId: paymentInfoData?._id,
  //       };
  //     }
  //   });

  //   return { updatedData, remainingAmount: remainingAmount.toFixed(2) };
  // };

  const updateFundingData = (
    fundingDueData,
    paymentInfoData,
    isFull = false,
    isInterest = false,
  ) => {
    let remainingAmount = parseFloat(paymentInfoData?.amount) || 0;

    const updatedData = fundingDueData.map((item) => {
      const totalValue = isFull
        ? parseFloat(item?.cost_value) + parseFloat(item?.interest)
        : isInterest
        ? parseFloat(item?.interest)
        : parseFloat(item?.cost_value);

      const previousPayments = item.paymentHistory || [];
      const newPaymentEntry = {
        paymentAmount: "0.00",
        paymentState: "open",
        currency: item?.currency,
        paydate: paymentInfoData?.date,
        paymentType: isInterest
          ? "Interest"
          : isFull
          ? "PIK-Interest"
          : "Principle",
        payment_id: paymentInfoData?._id,
      };

      if (remainingAmount >= totalValue) {
        remainingAmount -= totalValue;
        newPaymentEntry.paymentAmount = totalValue.toFixed(2);
        newPaymentEntry.paymentState = isFull ? "close" : "open";

        return {
          ...item,
          paymentApp: totalValue.toFixed(2),
          postAppStatus: isFull ? "Close" : "open",
          paymentType: newPaymentEntry.paymentType,
          paymentId: paymentInfoData?._id,
          paymentHistory: [...previousPayments, newPaymentEntry],
        };
      } else if (remainingAmount > 0) {
        const currentAmount = remainingAmount;
        remainingAmount = 0;
        newPaymentEntry.paymentAmount = currentAmount.toFixed(2);
        newPaymentEntry.paymentState = "open";

        return {
          ...item,
          paymentApp: currentAmount.toFixed(2),
          postAppStatus: "open",
          paymentType: newPaymentEntry.paymentType,
          paymentId: paymentInfoData?._id,
          paymentHistory: [...previousPayments, newPaymentEntry],
        };
      } else {
        // No new payment applied; just add the previous payments
        newPaymentEntry.paymentAmount = "0.00";
        newPaymentEntry.paymentState = "open";

        return {
          ...item,
          paymentApp: 0,
          postAppStatus: "Open",
          paymentType: newPaymentEntry.paymentType,
          paymentId: paymentInfoData?._id,
          paymentHistory: previousPayments, // Keep existing history without adding a new entry
        };
      }
    });

    return { updatedData, remainingAmount: remainingAmount.toFixed(2) };
  };

  // Update Manual Advanced Data
  const updateManualAdvData = () => {
    const { updatedData, remainingAmount } = updateFundingData(
      fundingDueData,
      paymentInfoData,
      true,
    );
    setReservedAmount(remainingAmount);
    setManualAdvData(updatedData);
  };

  // Handle Manual Advance Toggle
  const handleManualAdvanced = () => {
    setManualAdv((prev) => !prev);
    setManualAdvData(() => createDocRecords(fundingDueData?.length));
    updateManualAdvData();
  };

  // Update Auto Apply Interest
  const updateAutoInterest = async () => {
    const { updatedData, remainingAmount } = updateFundingData(
      fundingDueData,
      paymentInfoData,
      false,
      true,
    );
    setManualAdvData(updatedData);
    setReservedAmount(remainingAmount);
  };

  // Handle Apply Interest Toggle
  const handleApplyInterest = () => {
    setAutoApplyInterest((prev) => !prev);
    setManualAdvData(() => createDocRecords(fundingDueData?.length));
    updateAutoInterest();
  };

  // Update Auto Apply Cost
  const updateAutoCost = async () => {
    const { updatedData, remainingAmount } = updateFundingData(
      fundingDueData,
      paymentInfoData,
    );
    setManualAdvData(updatedData);
    setReservedAmount(remainingAmount);
  };

  console.log("autoApplyCost", autoApplyCost);
  // Handle Auto Apply Cost Toggle
  const handleAutoApplyCost = () => {
    setAutoApplyCost((prev) => !prev);
    setManualAdvData(() => createDocRecords(fundingDueData?.length));
    updateAutoCost();
  };

  // Update Auto Apply Full
  const updateAutoFull = async () => {
    const { updatedData, remainingAmount } = updateFundingData(
      fundingDueData,
      paymentInfoData,
      true,
    );
    setManualAdvData(updatedData);
    setReservedAmount(remainingAmount);
  };

  // Handle Auto Apply Full Toggle
  const handleAutoApplyFull = () => {
    setAutoApplyFull(true);
    setManualAdvData(() => createDocRecords(fundingDueData?.length));
    updateAutoFull();
  };

  // const updateDefaultAutoApplyFull = (fundingDueData, paymentInfoData) => {
  //   let remainingAmount = parseFloat(paymentInfoData?.amount);

  //   const updatedData = [...fundingDueData];

  //   const item = updatedData[0];
  //   const totalValue =
  //     parseFloat(item?.cost_value) + parseFloat(item?.face_value);

  //   if (remainingAmount >= totalValue) {
  //     remainingAmount -= totalValue;

  //     updatedData[0] = {
  //       ...item,
  //       paymentApp: totalValue.toFixed(2),
  //       postAppStatus: "Close",
  //       paymentType: "PIK-Interest",
  //       paymentId: paymentInfoData?._id,
  //     };
  //   } else if (remainingAmount > 0) {
  //     const currentAmount = remainingAmount;
  //     remainingAmount = 0;

  //     updatedData[0] = {
  //       ...item,
  //       paymentApp: currentAmount.toFixed(2),
  //       postAppStatus: "open",
  //       paymentType: "PIK-Interest",
  //       paymentId: paymentInfoData?._id,
  //     };
  //   } else {
  //     updatedData[0] = {
  //       ...item,
  //       paymentApp: 0,
  //       postAppStatus: "Open",
  //       paymentType: "Interest",
  //       paymentId: paymentInfoData?._id,
  //     };
  //   }

  //   return { updatedData, remainingAmount: remainingAmount.toFixed(2) };
  // };

  const updateDefaultAutoApplyFull = (fundingDueData, paymentInfoData) => {
    let remainingAmount = parseFloat(paymentInfoData?.amount);

    const updatedData = [...fundingDueData];
    const item = updatedData[0];
    const totalValue = parseFloat(item?.face_value);

    // Check for previous payments in paymentHistory
    const previousPayments = item.paymentHistory || [];
    const previousPaymentsTotal = previousPayments.reduce((acc, payment) => {
      return acc + parseFloat(payment.paymentAmount || 0);
    }, 0);

    // Adjust total based on previous payments
    const adjustedTotal = totalValue - previousPaymentsTotal;

    let newPaymentEntry = {
      paymentAmount: remainingAmount.toFixed(2),
      paymentState: "open",
      paymentType: "PIK-Interest",
      payment_id: paymentInfoData?._id,
      currency: item?.currency,
      paydate: paymentInfoData?.date,
    };

    if (remainingAmount >= adjustedTotal) {
      remainingAmount -= adjustedTotal;
      newPaymentEntry.paymentAmount = adjustedTotal.toFixed(2);
      newPaymentEntry.paymentState = "close";

      updatedData[0] = {
        ...item,
        paymentApp: adjustedTotal.toFixed(2),
        postAppStatus: "Close",
        paymentType: "PIK-Interest",
        paymentId: paymentInfoData?._id,
        paymentHistory: [...previousPayments, newPaymentEntry],
      };
    } else if (remainingAmount > 0) {
      const currentAmount = remainingAmount;
      remainingAmount = 0;
      newPaymentEntry.paymentAmount = currentAmount.toFixed(2);
      newPaymentEntry.paymentState = "open";

      updatedData[0] = {
        ...item,
        paymentApp: currentAmount.toFixed(2),
        postAppStatus: "Open",
        paymentType: "PIK-Interest",
        paymentId: paymentInfoData?._id,
        paymentHistory: [...previousPayments, newPaymentEntry],
      };
    } else {
      newPaymentEntry.paymentAmount = "0.00";
      newPaymentEntry.paymentState = "open";
      newPaymentEntry.paymentType = "Interest";

      updatedData[0] = {
        ...item,
        paymentApp: previousPaymentsTotal.toFixed(2),
        postAppStatus: "Open",
        paymentType: "Interest",
        paymentId: paymentInfoData?._id,
        paymentHistory: previousPayments, // No new entry as no new payment was applied
      };
    }

    return { updatedData, remainingAmount: remainingAmount.toFixed(2) };
  };

  const assignSingular = () => {
    console.log("assignSingular");
    setAutoApplyFull(true);
    setManualAdvData(() => createDocRecords(1));
    const { updatedData, remainingAmount } = updateDefaultAutoApplyFull(
      fundingDueData,
      paymentInfoData,
    );
    setManualAdvData(updatedData);
    setReservedAmount(remainingAmount);
  };

  useEffect(() => {
    if (paymentInfoData && fundingDueData?.length) {
      assignSingular();
    }
  }, [paymentInfoData, id, fundingDueData]);

  const handleAssignmentTypeChange = (value) => {
    if (value === "Assign Signular") {
      assignSingular();
    } else if (value === "Auto Apply (Full)") {
      handleAutoApplyFull();
    } else if (value === "Auto Apply (Cost)") {
      handleAutoApplyCost();
    } else if (value === "Auto Apply (Interest)") {
      handleApplyInterest();
    } else if (value === "Manual") {
      setAssigmentType("Manual");
    }
  };

  return (
    <>
      <div className="mb-4">
        <Breadcrumb
          labels={["Payments", "Payment Assignment"]}
          stepsToRemove={2}
        />
        <Heading className="HeadingStyle">Payment Assignment</Heading>
      </div>

      <div className={style.info_history_wrap}>
        <div className={style.tableWrap}>
          <table class="table">
            <thead>
              <tr>
                <th colSpan={2}>Payment Information</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={style.label}>Obligor name</td>
                <td className={style.value}>
                  {paymentInfoData?.obligor_name
                    ? paymentInfoData?.obligor_name
                    : paymentInfoData?.client_name}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Payment Date</td>
                <td className={style.value}>
                  {moment(paymentInfoData?.date).format("YYYY-MM-DD")}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Amount</td>
                <td className={style.value}>{formatNumberInternational(paymentInfoData?.amount)}</td>
              </tr>
              <tr>
                <td className={style.label}>Currency</td>
                <td className={style.value}>{paymentInfoData?.currency}</td>
              </tr>
              <tr>
                <td className={style.label}>Status</td>
                <td className={style.value}>{paymentInfoData?.status}</td>
              </tr>
              <tr>
                <td className={style.label}>Participant ID</td>
                <td className={style.value}>--</td>
              </tr>
              <tr>
                <td className={style.label}>Reserve Amount</td>
                <td className={style.value}>
                  {reservedAmount ? formatNumberInternational(reservedAmount) : 0}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Assignment Type</td>
                <td className={style.value}>
                  <PrimitiveDropdown
                    selected={assignmentType}
                    options={[
                      "Assign Signular",
                      "Auto Apply (Full)",
                      "Auto Apply (Cost)",
                      "Auto Apply (Interest)",
                      "Manual",
                    ]}
                    onSelect={(value) => {
                      setAssigmentType(value);
                      handleAssignmentTypeChange(value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className={style.label}>Calculation Date</td>
                <td className={style.value}>
                  {/* <div style={{ position: "relative" }}>
                    <DateSelector
                      placeholder="Calculation Date"
                      selectedDate={paymentInfoData?.date}
                      disabled
                    />
                  </div> */}
                  {moment(paymentInfoData?.date).format("YYYY-MM-DD")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={style.history_wrap}>
          <div className={style.tableWrapRight}>
            <table className="w-100 border-collapse">
              <thead>
                <tr className="bg-gray">
                  <th colSpan="8" className="border p-2 text-left">
                    Obligor Assets Due
                  </th>
                  <th
                    colSpan={
                      manualAdv ||
                      autoApplyInterest ||
                      autoApplyFull ||
                      autoApplyCost
                        ? "6"
                        : "5"
                    }
                    className="border p-2 text-left"
                  >
                    As of Calculation Date
                  </th>
                </tr>
                <tr className="bg-gray">
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Obligor
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Due Date
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Client Ref
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Fund
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Status
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Currency
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Cost
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Face value
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Priciple OutStanding
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Interest Accured
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Amount to Close
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Payment Application
                  </th>
                  <th
                    className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                  >
                    Post Application Status
                  </th>
                  {(manualAdv ||
                    autoApplyInterest ||
                    autoApplyFull ||
                    autoApplyCost) && (
                    <th
                      className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                    >
                      Repayment Type
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {fundingDueData?.map((row, index) => {
                  const isPaymentOpen =
                    row?.paymentHistory?.[0]?.paymentState === "open";
                  const isEditable = manualAdv && isPaymentOpen;
                  return (
                    <>
                      <tr className="" key={index}>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {row.obligor_name
                            ? row.obligor_name
                            : row?.client_name}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {moment(row?.due_date).format("YYYY-MM-DD")}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {row?.transaction_type === "Obligor" && (
                            <>{breakStringInMiddle(row?.invoice_number)}</>
                          )}
                          {(row?.transaction_type === "Supplier Credit-1" ||
                            row?.transaction_type === "Supplier Credit" ||
                            row?.transaction_type === "Supplier") && (
                            <>
                              <>{breakStringInMiddle(row?.invoice_number)}</>
                            </>
                          )}
                          {(row?.transaction_type === "Client" ||
                            row?.transaction_type === "Note") && (
                            <>{breakStringInMiddle(row?.referance)}</>
                          )}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {row?.funder}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {row?.status === "Funded" ? "Open" : row?.status}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {row?.currency}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {formatNumberInternational(row?.cost_value)}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {formatNumberInternational(parseFloat(row?.face_value))}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {formatNumberInternational(row?.cost_value)}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {formatNumberInternational(row?.interest)}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {formatNumberInternational(parseFloat(row?.face_value))}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {manualAdv ||
                          autoApplyInterest ||
                          autoApplyFull ||
                          autoApplyCost ? (
                            <CustomInput
                              onchange={(data) => {
                                const tempData = [...manualAdvData];
                                tempData[index].paymentApp = data;
                                setManualAdvData(tempData);
                              }}
                              value={manualAdvData[index].paymentApp}
                              inputType="text"
                            />
                          ) : row?.paymentHistory?.[0]?.paymentAmount ? (
                            row?.paymentHistory?.[0]?.paymentAmount
                          ) : (
                            0
                          )}
                        </td>
                        <td className={`border p-2 ${style.tabelRowStyleWrap}`}>
                          {manualAdv ||
                          autoApplyInterest ||
                          autoApplyFull ||
                          autoApplyCost ? (
                            <CustomDropDown
                              dropDownItem={[
                                { label: "Open", value: "Open" },
                                { label: "Close", value: "Close" },
                              ]}
                              onSelect={(value) => {
                                const tempData = [...manualAdvData];
                                tempData[index].postAppStatus = value;
                                setManualAdvData(tempData);
                              }}
                              selectedValue={
                                manualAdvData[index]?.postAppStatus
                              }
                            />
                          ) : row?.paymentHistory?.[0]?.paymentState ? (
                            row?.paymentHistory?.[0]?.paymentState
                          ) : (
                            "open"
                          )}
                        </td>
                        {(manualAdv ||
                          autoApplyInterest ||
                          autoApplyFull ||
                          autoApplyCost) && (
                          <td
                            className={`border p-2 ${style.tabelRowStyleWrap}`}
                          >
                            <CustomDropDown
                              dropDownItem={[
                                { label: "Interest", value: "Interest" },
                                { label: "Principle", value: "Principle" },
                                {
                                  label: "PIK-Interest",
                                  value: "PIK-Interest",
                                },
                              ]}
                              onSelect={(value) => {
                                const tempData = [...manualAdvData];
                                tempData[index].paymentType = value;
                                setManualAdvData(tempData);
                              }}
                              selectedValue={manualAdvData[index]?.paymentType}
                            />
                          </td>
                        )}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="mt-auto">
        <div className={style.CustomButtonWrap}>
          <div className={style.autoWrap}>
            <CustomButton
              text="Confirm and Apply"
              size="btn-lg"
              type="btn-primary"
              handleClick={() => {
                handleConfirmAndApply();
              }}
            />
          </div>
          <div>
            <CustomButton
              text="Back"
              size="btn-lg"
              type="btn-outline-dark"
              handleClick={() => {
                navigate("/dashboard/payments");
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ReviewView;
