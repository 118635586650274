import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../atoms/CustomButton";
import { Heading } from "../../../../atoms/Heading";
import Search from "../../../../atoms/Search";
import style from "./index.module.scss";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import { memo, useCallback, useEffect, useState } from "react";
import NoContentCard from "../../../../atoms/NoContentCard";
import { deleteData, getData } from "../../../../../services";
import { useCookies } from "react-cookie";
import Edit from "../../../../icons/EditBlue";
import Pagination from "../../../../atoms/Pagination";
import Modal from "../../../../atoms/modals/DeleteModal";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import Delete from "../../../../icons/Delete";
import CustomMenu from "../../../../molecules/CustomMenu";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import moment from "moment";
import FilterComponent from "../../../../molecules/filters/FilterComponent";
import {
  CarrierNameData,
  CurrencyData,
  LimitTypeData,
  PolicyNameData,
  ReportingData,
} from "../../../../../utils/staticData";
import { useSelector } from "react-redux";
import Upload from "../../../../icons/Upload";
import { downloadReport } from "../../../../../services/downloadService";

const InsuranceManagement = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Insurance;
  const [InsuranceData, setInsuranceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [searchData, setSearchData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [filters, setFilters] = useState({});
  const [loader, setLoader] = useState(true);

  const GetInsuranceData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "/insurance/getAllInsurance",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          currency: filters?.["Currency"],
          carrier_name: filters?.["Carrier Name"],
          policy_name: filters?.["Policy name"],
          limit_type: filters?.["Limit Type"],
          reporting_terms: filters?.["Reporting terms"],
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      setInsuranceData(res?.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    GetInsuranceData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  const CustomNoRowsOverlay = memo(() => {
    return (
      !loader && (
        <>
          <NoContentCard
            title="No Insurance Added Yet!"
            handleClick={() => {
              navigate("/dashboard/insurance/add");
            }}
            tag="Create Insurance"
            type="Pricing"
            subtitle="Currently no insurance created yet click on a 
          Create Insurance button to create insurance"
            classes="d-flex align-items-center"
            {...(roleData && roleData?.["add/edit"]
              ? {
                  handleClick: () => {
                    navigate("/dashboard/insurance/add");
                  },
                  subtitle:
                    "Currently no insurance created yet click on a Create Insurance button to create insurance",
                }
              : {})}
          />
        </>
      )
    );
  });

  const menu = [
    {
      icon: <Edit height="20" width="20" />,
      label: "Edit",
      onClick: () => {
        navigate(
          `edit/${selectedRow?._id}/${selectedRow?.counter_id}/${selectedRow?.client_id}`,
        );
      },
    },
    {
      icon: <Delete />,
      label: "Delete",
      onClick: () => {
        setModalStateDelete({
          isModalOpen: true,
          name: selectedRow.policy_name,
          modalId: selectedRow._id,
          clientId: selectedRow.counter_id,
        });
        setAnchorEl(null);
      },
    },
  ];

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "counter_name",
      headerName: "Obligor Name",
      flex: 0.8,
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.6,
      renderCell: (params) => {
        return <div className={style.valueStyle}>{params?.row?.currency}</div>;
      },
    },
    {
      field: "limit",
      headerName: "Limit ",
      flex: 0.5,
      renderCell: (params) => {
        return <div className={style.valueStyle}>{params?.row?.limit}</div>;
      },
    },
    {
      field: "carrier_name",
      headerName: "Carrier Name",
      flex: 0.8,
    },
    {
      field: "policy_name",
      headerName: "Policy Name",
      flex: 0.8,
    },
    {
      field: "limit_type",
      headerName: "Limit Type",
      flex: 0.8,
    },
    {
      field: "start_date",
      headerName: "Start Date ",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {moment(params?.row?.start_date).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      field: "end_date",
      headerName: "End Date ",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {moment(params?.row?.end_date).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      field: "reporting_terms",
      headerName: "Reporting Terms ",
      flex: 0.8,
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.6,
      renderCell: (params, index) => {
        return (
          <>
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params.row)}
            >
              <HorizontalMenu />
            </span>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={getMenuList()}
            />
          </>
        );
      },
    },
  ];

  const getMenuList = () => {
    if (roleData && roleData?.["add/edit"] && roleData?.delete) {
      return menu;
    } else if (roleData && roleData?.delete) {
      return [menu[1]];
    } else if (roleData && roleData?.["add/edit"]) {
      return [menu[0]];
    } else return [];
  };
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "insurance/deleteInsurance",
        token: cookies.t,
        params: {
          id: modalStateDelete?.modalId,
          clientID: modalStateDelete?.clientId,
        },
      });
      if (res) {
        toast.success("Insurance Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetInsuranceData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const filtersData = {
    Currency: CurrencyData,
    "Carrier Name": CarrierNameData,
    "Policy name": PolicyNameData,
    "Limit Type": LimitTypeData,
    "Reporting terms": ReportingData,
  };

  function generateRandomString(length) {
    return Math.random()
      .toString(36)
      .substring(2, 2 + length);
  }

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "insurance/exportInsurance",
      token: cookies.t,
      fileName: "Insurance",
      params: {
        search: searchData,
        currency: filters?.["Currency"],
        carrier_name: filters?.["Carrier Name"],
        policy_name: filters?.["Policy name"],
        limit_type: filters?.["Limit Type"],
        reporting_terms: filters?.["Reporting terms"],
        group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
      },
    });

    if (res) {
      toast.success("Insurance Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Insurance</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />
          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          <FilterComponent
            filterData={filtersData}
            onFilterChange={(filter) => {
              setFilters(filter);
            }}
          />
          {roleData && roleData?.["add/edit"] && (
            <CustomButton
              text="Create Insurance"
              type="btn-primary"
              handleClick={() => {
                navigate("/dashboard/insurance/add");
              }}
            />
          )}
        </div>
      </div>
      <CustomDatagrid
        rows={InsuranceData?.data || []}
        getRowId={(row) => generateRandomString(25)}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        loader={loader}
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {/* {InsuranceData?.pageDetails?.totalCount > 10 && ( */}
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={InsuranceData?.pageDetails?.totalCount}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
      {/* )} */}
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Insurance"
        name={modalStateDelete.name}
      />
    </>
  );
};
export default InsuranceManagement;
